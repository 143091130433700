import { useNavigate } from 'react-router-dom';
import ImgCheck from '../assets/img/Check.png';

const Finish =()=>{
    
    return(
        <div className="text-center h-screen">
                <div className="items-center my-48">
                    <h1>Payment Received</h1>
                    <div>
                        <img className='mx-auto' src={ImgCheck} width={150}/>
                    </div>
                    <div className="text-page">
                        You can leave this page.
                    </div>
                </div>
        </div>
    )
}

export default Finish