import { useState } from 'react'
import { Modal } from 'components'
import axios from 'axios'
import nProgress from 'nprogress'
import toast from 'react-hot-toast'
import { lang } from 'config'
import { RotateAltIcon } from 'components/icons'

const Content = ({ data, success }) => {
    const handleSubmit = (e) => {
        e.preventDefault()
        nProgress.start()

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/merchant/restore`, {
            merchant_id: data?.merchant_id
        }).then((response) => {
            success()
            toast.success(response.data.message)
        }).catch(error => {
            toast.error(error.response.data.message)
            console.log(error.response)
            nProgress.done()
        })
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            {lang.are_you_sure_you_want_to_restore} <span className="inline-flex px-3 py-1 text-sm font-medium rounded-xl bg-neutral-200 text-neutral-600">{data?.merchant_id}</span> ?
            <div className="text-xs">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.restore}</span>
                </button>
            </div>
        </form>
    )
}

const Restore = ({ data, onSuccess }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSuccess = () => {
        closeModal()
        onSuccess()
    }

    return (
        <>
            <button onClick={openModal} class={`bg-neutral-800 inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90`}>
                <RotateAltIcon className="w-6 h-6" />
            </button>
            <Modal isOpen={isOpen} onClose={closeModal} title={`${lang.restore} ${lang.data}`} content={<Content data={data} success={handleSuccess} />} />
        </>
    )
}

export default Restore