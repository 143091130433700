import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { motion } from "framer-motion"

import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import axios from "axios"
import { useAuth } from "contexts/auth-context"
import { useLayoutEffect } from "react"
import Form from "./form"
import nProgress from "nprogress"
import toast from "react-hot-toast"
import { useSWRConfig } from "swr"

const CreateInvoice = ({ title }) => {
    const { user } = useAuth()
    const { cache } = useSWRConfig()

    const location = useLocation()
    const navigate = useNavigate()

    const [prevRouteState, setPrevRouteState] = useState({})

    const [errors, setErrors] = useState({})

    useLayoutEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }

        getPrevRouteState()
    }, [])

    const handleSubmit = (data) => {
        nProgress.start()

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/invoice/store`, {
            type: data.type,
            payor_or_payee_code: data.payor_or_payee_code,
            currency: data.currency,
            rate: data.rate,
            dpp: data.dpp,
            ppn: data.ppn,
            ppn_percentage: data.ppn_percentage,
            pph: data.pph,
            pph_percentage: data.pph_percentage,
            tax_invoice_number: data.tax_invoice_number,
            rebate: data.rebate,
            discount: data.discount,
            advance_payment: data.advance_payment,
            grand_total: data.grand_total,
            note: data.note,
            invoice_date: data.invoice_date,
            due_date: data.due_date,
            invoice_detail: data.invoice_detail,
            transaction_type: data.transaction_type,
            status: 'outstanding',
            branch_id: data.branch_id,
            created_by: user?.name,
        }).then((response) => {
            cache.clear()
            navigate({ pathname: '/invoice' })
            toast.success(response.data.message)
        }).catch(error => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            nProgress.done()
            toast.error(error.response.data.message)
            console.log(error.response)
        })
    }

    return (
        <AppLayout title={title}>
            <div className="p-4 lg:p-8 space-y-16">
                <div>
                    {prevRouteState?.back && (
                        <button>
                            <Link to={location.state?.from} state={{ back: null, from: location.pathname, transition: 'slide' }} className="transition hover:opacity-50">
                                <motion.h3 layout transition={{ duration: .5, type: "tween" }} layoutId={prevRouteState?.back} className="text-sm">{prevRouteState?.back}</motion.h3>
                            </Link>
                        </button>
                    )}
                    <Transition type="slide" reversed>
                        <h1 className="text-3xl font-medium">
                            {title}
                        </h1>
                    </Transition>
                </div>
                <Transition type="slide" reversed>
                    <Form errors={errors} onSubmit={handleSubmit} />
                </Transition>
            </div>
        </AppLayout>
    )
}

export default CreateInvoice