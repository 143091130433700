import { useState, useEffect } from "react"

import ErrorMessage from "components/forms/error-message"
import _ from "lodash"
import Label from "components/forms/label"
import Input from "components/forms/input"
import { lang } from "config"

const Form = ({ data = {}, errors, onSubmit }) => {
    const id = data.id ?? undefined
    const [name, setName] = useState(data.name ?? null)
    const [code, setCode] = useState(data.code ?? null)
    const [contact, setContact] = useState(data.contact ?? null)
    const [address, setAddress] = useState(data.address ?? null)

    const handleSubmit = (e) => {
        e.preventDefault()
        onSubmit({
            id,
            name,
            code,
            contact,
            address
        })
    }
    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                    <Label htmlFor="name" value={lang.name} />
                    <Input onChange={e => setName(e.target.value)} error={errors.name} value={name} id="name" />
                    <ErrorMessage error={errors.name} />
                </div>

                <div>
                    <Label htmlFor="code" value={lang.code} />
                    <Input onChange={e => setCode(e.target.value)} error={errors.code} value={code} id="code" />
                    <ErrorMessage error={errors.code} />
                </div>

                <div>
                    <Label htmlFor="contact" value={lang.contact} />
                    <Input onChange={e => setContact(e.target.value)} error={errors.contact} value={contact} id="contact" />
                    <ErrorMessage error={errors.contact} />
                </div>

                <div className="col-span-2">
                    <Label htmlFor="address" value={lang.address} />
                    <textarea onChange={e => setAddress(e.target.value)} value={address} name="address" id="address" rows="5" className={`${errors.address ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}></textarea>
                    <ErrorMessage error={errors.address} />
                </div>
            </div>
            <div className="text-xs">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}

export default Form