import { useState, useEffect } from "react"

import useBranches from "repositories/branch"
import nProgress from "nprogress"
import ErrorMessage from "components/forms/error-message"
import lodash from "lodash"
import deepdash from "deepdash"
import { useAuth } from "contexts/auth-context"
import { lang } from "config"
import Label from "components/forms/label"
import Input from "components/forms/input"
import { XIcon } from "components/icons"
import Create from "./terminal/create"
import ErrorTooltip from "components/forms/error-tooltip"
import { matchObjectRegex } from "helpers/array-helper"
import useBanks from "repositories/bank"

const Form = ({ data, errors, onSubmit }) => {
    const _ = deepdash(lodash)

    const { data: branchSelection, isLoading: isLoadingBranches } = useBranches({
        paginate: false
    })

    const { data: bankSelection, isLoading: isLoadingBanks } = useBanks({
        paginate: false
    })

    const bankSelections = [
        {
            id: 1,
            name: "BCA",
            code: "bca"
        },
        {
            id: 2,
            name: "BRI",
            code: "bri"
        }
    ]

    useEffect(() => {
        if (isLoadingBranches) {
            nProgress.start()
        } else {
            nProgress.done()
        }
    }, [isLoadingBranches])

    const [terminals, setTerminals] = useState(data?.terminals ?? [])

    const [merchantId, setMerchantId] = useState(data?.merchant_id ?? null)
    const [name, setName] = useState(data?.name ?? null)
    const [branch, setBranch] = useState(data?.branch?.id ?? null)
    const [bank, setBank] = useState(data?.bank ?? null)

    const createTerminal = (data) => {
        setTerminals([...terminals, data])
    }

    const deleteTerminal = (data) => {
        setTerminals(terminals.filter((row) => row.terminal_id !== data.terminal_id))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const terminalData = []

        terminals.map((row) => {
            const features = row.features.map((feature) => {
                return feature.code
            })

            terminalData.push({
                ...row,
                features
            })
        })

        onSubmit({
            merchant_id: merchantId,
            branch_id: branch,
            name,
            bank,
            terminals: terminalData
        })
    }
    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="overflow-hidden border sm:rounded-xl">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                                    <div>
                                        <Label htmlFor="branch" value={lang.branch} />
                                        <select onChange={(e) => setBranch(e.target.value)} value={branch} name="branch" id="branch" className={`${errors.branch_id ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                            {isLoadingBranches && (
                                                <option value={null} disabled>{lang.loading}</option>
                                            )}
                                            {!isLoadingBranches && (
                                                <option value={null} disabled selected={branch === null}>{`-- ${lang.choose} ${lang.branch} --`}</option>
                                            )}
                                            {!isLoadingBranches && branchSelection?.map(row => (
                                                <option value={row.id}>{row.name}</option>
                                            ))}
                                        </select>
                                        <ErrorMessage error={errors.branch_id} />
                                    </div>
                                    <div>
                                        <div>
                                            <Label htmlFor="merchant_id" value={lang.merchant_id} />
                                            <Input disabled={data} error={errors.merchant_id} onChange={(e) => setMerchantId(e.target.value)} value={merchantId} id="merchant_id" />
                                            <ErrorMessage error={errors.merchant_id} />
                                        </div>
                                    </div>
                                    <div>
                                        <Label htmlFor="bank" value={lang.bank} />
                                        <select onChange={(e) => setBank(e.target.value)} value={bank} name="bank" id="bank" className={`${errors.bank ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                            {/* {isLoadingBranches && (
                                                <option value={null} disabled>{lang.loading}</option>
                                            )} */}
                                            {!false && (
                                                <option value={null} disabled selected={bank === null}>{`-- ${lang.choose} ${lang.bank} --`}</option>
                                            )}
                                            {!false && bankSelection?.map(row => (
                                                <option value={row.code}>{row.name}</option>
                                            ))}
                                        </select>
                                        <ErrorMessage error={errors.bank} />
                                    </div>
                                    <div>
                                        <div>
                                            <Label htmlFor="name" value={lang.name} />
                                            <Input error={errors.name} onChange={(e) => setName(e.target.value)} value={name} id="name" />
                                            <ErrorMessage error={errors.name} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200" />
                </div>
            </div>

            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.terminals}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_provide_edc_terminal_list}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="overflow-hidden border sm:rounded-xl">
                            <div className="px-4 py-5 space-y-4 bg-white sm:p-6">
                                <div className="space-y-2">
                                    <div className={`${(errors.terminals || matchObjectRegex(errors, 'terminals.*.terminal_id')) ? 'border-red-200' : 'border-neutral-200'} overflow-x-auto border rounded-xl`}>
                                        <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                            <thead className="bg-neutral-50 rounded-t-3xl">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.terminal_id}</th>
                                                    <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.account_number}</th>
                                                    <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.features}</th>
                                                    <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-neutral-200">
                                                {/* When there are no data available */}
                                                {terminals.length === 0 && (
                                                    <tr className="text-center">
                                                        <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                            {lang.no_data}
                                                        </td>
                                                    </tr>
                                                )}

                                                {terminals.length > 0 && terminals.map((row, index) => (
                                                    <tr key={row.terminal_id}>
                                                        <td class="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                            <div className="inline-flex items-center space-x-2">
                                                                <span>{row.terminal_id}</span>
                                                                <ErrorTooltip error={errors[`terminals.${index}.terminal_id`]} />
                                                            </div>
                                                        </td>
                                                        <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                            {row.account_number}
                                                        </td>
                                                        <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                            {row.features.map((data, index) => (<>{`${data.name}${row.features.length === index + 1 ? '' : ', '}`}</>))}
                                                        </td>
                                                        <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                            <div class="inline-flex items-center space-x-2">
                                                                <button type="button" onClick={() => deleteTerminal(row)} class="bg-red-800 inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90">
                                                                    <XIcon className="w-6 h-6" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <ErrorMessage error={errors.terminals} />
                                </div>
                                <Create onSubmit={(value) => createTerminal(value)} error={errors.terminals} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${lang.save}`}</span>
                </button>
            </div>
        </form>
    )
}

export default Form