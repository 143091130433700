import { useState } from 'react'
import { Modal } from 'components'
import { useEffect } from 'react'
import moment from 'moment/moment'
import SupplierIcon from 'components/icons/supplier'
import { PlusIcon, UsersIcon } from 'components/icons'
import useBills from 'repositories/bill'
import useBranches from 'repositories/branch'
import useCustomers from 'repositories/customer'
import useSuppliers from 'repositories/supplier'
import Label from 'components/forms/label'
import Input from 'components/forms/input'
import NoRedirectPagination from 'components/no-redirect-pagination'
import { useRef } from 'react'
import InputDate from 'components/forms/input-date'
import { lang } from "config"
import useTypes from 'repositories/type'

const usePrevious = (value) => {
    const ref = useRef()

    useEffect(() => {
        ref.current = value
    }, [value])

    return ref.current
}

const Content = ({ selected, onSelect, filter = {} }) => {
    const [selectedBill, setSelectedBill] = useState(selected)

    const [page, setPage] = useState(1)

    const [search, setSearch] = useState(undefined)
    const [branch, setBranch] = useState(undefined)
    const [payorOrPayee, setPayorOrPayee] = useState(filter.payor_or_payee_code)
    const [type, setType] = useState()
    const [from, setFrom] = useState(undefined)
    const [to, setTo] = useState(undefined)

    const [grandTotal, setGrandTotal] = useState(0)

    const { data: billSelection, isLoading: isLoadingBills, mutate: mutateBills, isValidating: isValidatingBills } = useBills({
        page,
        paginate: 8,
        search,
        branch,
        payor_or_payee: payorOrPayee,
        status: 'outstanding',
        type,
        from,
        to,
        selection: true
    })

    const { data: branchSelection, mutate: mutateBranches } = useBranches({
        paginate: false
    })

    const { data: customerSelection, mutate: mutateCustomers } = useCustomers({
        paginate: false
    })

    const { data: typeSelection, mutate: mutateTypes } = useTypes({
        model: 'Bill'
    })

    const prevSelectedBill = usePrevious(selectedBill)

    useEffect(() => {
        if (filter) {
            setPayorOrPayee(filter.payor_or_payee_code)
        }
    }, [filter])

    useEffect(() => {
        mutateBills()

        if (selectedBill) {
            var grandTotal = 0

            selectedBill.map((row) => {
                if (row.transaction_type === 0) {
                    grandTotal += parseFloat(row.grand_total)
                } else if (row.transaction_type === 1) {
                    grandTotal -= parseFloat(row.grand_total)
                }
            })

            setGrandTotal(Math.abs(grandTotal))
        } else {
            setGrandTotal(0)
        }

        if (prevSelectedBill !== selectedBill && !payorOrPayee) {
            setPage(1)
        }
    }, [selectedBill, page])

    useEffect(() => {
        // setSelectedBill([])
        // setSearch(undefined)
        mutateBills()
    }, [branch, payorOrPayee, from, to])

    // useEffect(() => {
    //     setSelectedBill([])
    // }, [search])

    const handleChange = (value) => {
        if (selectedBill.find(row => row.bill_number === value.bill_number)) {
            setSelectedBill(selectedBill.filter(row => row.bill_number !== value.bill_number))
        } else {
            setSelectedBill([...selectedBill, value])
        }
    }

    const handleSelect = () => {
        onSelect(selectedBill)
    }

    const clearSelection = () => {
        setSelectedBill([])
    }

    const resetFilter = (e) => {
        e.preventDefault()

        setSearch(undefined)
        setBranch(undefined)
        setType(undefined)
        setFrom(undefined)
        setTo(undefined)
    }

    const selectAll = () => {
        if (billSelection?.data.length === selectedBill?.length) {
            setSelectedBill([])
        } else {
            setSelectedBill(billSelection?.data)
        }
    }

    return (
        <div className="mt-8">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 md:grid-cols-9">
                <div className="col-span-2">
                    <Label htmlFor="search" value={lang.search} />
                    <Input onChange={e => setSearch(e.target.value)} value={search} id="search" />
                </div>

                <div className="col-span-2">
                    <label for="branch" className="block text-xs text-neutral-700">{lang.branch}</label>
                    <select onChange={e => setBranch(e.target.value)} value={branch} name="branch" id="branch" className={`border-neutral-200 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                        <option value={undefined} selected={branch === undefined}></option>
                        {branchSelection?.map(row => (
                            <option value={row.id}>{row.name}</option>
                        ))}
                    </select>
                </div>

                <div className="col-span-2">
                    <label for="type" className="block text-xs text-neutral-700">{lang.type}</label>
                    <select onChange={e => setType(e.target.value)} value={type} name="type" id="type" className={`border-neutral-200 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                        <option value={undefined} selected={type === undefined}></option>
                        {typeSelection?.map(row => (
                            <option value={row.code}>{row.name}</option>
                        ))}
                    </select>
                </div>

                <div className="col-span-2">
                    <label for="customer" className="block text-xs text-neutral-700">{lang.customer}</label>
                    <select disabled onChange={e => setPayorOrPayee(e.target.value)} value={payorOrPayee} name="customer" id="customer" className={`${true ? 'bg-neutral-100 opacity-70' : ''} border-neutral-200 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                        <option value={undefined}></option>
                        {customerSelection?.map(row => (
                            <option value={row.customer_code}>{row.name}</option>
                        ))}
                    </select>
                </div>

                <div className="col-span-2">
                    <Label htmlFor="from" value={lang.from} />
                    <InputDate id="from" maxDate={Date.parse(to)} onChange={(value) => setFrom(moment(value).format('Y-MM-DD'))} value={Date.parse(from)} selected={Date.parse(from)} />
                </div>

                <div className="col-span-2">
                    <Label htmlFor="to" value={lang.to} />
                    <InputDate id="to" minDate={Date.parse(from)} onChange={(value) => setTo(moment(value).format('Y-MM-DD'))} value={Date.parse(to)} selected={Date.parse(to)} />
                </div>

                {(branch || search) && (selectedBill.length === 0 || from || to) && (
                    <div className="flex items-center text-xs text-neutral-700">
                        <button onClick={resetFilter} className="mt-5">{lang.reset}</button>
                    </div>
                )}
            </div>

            <div className="mt-4 overflow-x-auto border border-neutral-200 rounded-xl">
                <table className="min-w-full overflow-x-auto">
                    <thead className="border-b bg-neutral-50 rounded-t-3xl border-neutral-200">
                        <tr>
                            <th scope="col" className="relative px-6 py-3">
                                <span className="sr-only">{lang.action}</span>
                                <div className="flex items-center h-5">
                                    <input disabled={!payorOrPayee} onChange={() => selectAll()} checked={billSelection?.data.length !== 0 && billSelection?.data.length === selectedBill?.length} id="pph" name="pph" type="checkbox" className={`${!payorOrPayee ? "bg-neutral-100 cursor-default" : "cursor-pointer"} w-4 h-4 rounded-full border-neutral-300 text-neutral-800`} />
                                </div>
                            </th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">No. Bill</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.grand_total}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.branch}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.customer_or_supplier}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.type}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_type}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.bill_date}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.customer_or_supplier_code}</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-neutral-200">
                        {/* When loading */}
                        {isLoadingBills && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.loading_data}
                                </td>
                            </tr>
                        )}

                        {/* When there are no list available */}
                        {!isLoadingBills && !(branch || payorOrPayee) && billSelection?.data.length === 0 && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.no_data}
                                </td>
                            </tr>
                        )}

                        {/* When there are no list available on searching */}
                        {!isLoadingBills && (branch || payorOrPayee) && billSelection?.data.length === 0 && (
                            <tr className="text-center">
                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {lang.no_result}
                                </td>
                            </tr>
                        )}

                        {!isLoadingBills && billSelection?.data.length !== 0 && billSelection?.data.map(row => (
                            <tr onClick={() => handleChange(row)} className={`${selectedBill.length !== 0 && selectedBill.find(selected => selected.bill_number == row.bill_number) ? "bg-neutral-50" : ""} transition cursor-pointer select-none hover:bg-neutral-100`} key={row.bill_number}>
                                <td className="pl-6">
                                    <div className="flex items-center h-5">
                                        <input onChange={() => handleChange(row)} checked={selectedBill.length !== 0 && selectedBill.find(selected => selected.bill_number == row.bill_number)} id="pph" name="pph" type="checkbox" className="w-4 h-4 rounded-full cursor-pointer border-neutral-300 text-neutral-800" />
                                    </div>
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.bill_number}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.currency && Intl.NumberFormat('id-Id', { style: 'currency', currency: row.currency }).format(row.grand_total)}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.branch?.name}
                                </td>
                                <td class="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    <div className="flex items-center space-x-2">
                                        <span>
                                            {(row.type?.code === 'po' || row.type?.code === 'rt-po' || row.type?.code === 'rt-adv') ? (
                                                <SupplierIcon className="w-4 h-4" stroke={1.5} />
                                            ) : (
                                                <UsersIcon className="w-4 h-4" stroke={1.5} />
                                            )}
                                        </span>
                                        <span>
                                            {(row.type?.code === 'po' || row.type?.code === 'rt-po' || row.type?.code === 'rt-adv') ? row.supplier?.name : row.customer?.name}
                                        </span>
                                    </div>
                                </td>
                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {row.type?.name}
                                </td>
                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {row.transaction_type === 0 ? lang.income : lang.expense}
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {moment(row.bill_date).format('MMMM D, YYYY')}
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {row.payor_or_payee_code}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td scope="col" className="px-6 py-2 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Total</td>
                            <td scope="col" className="px-6 py-2 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{Intl.NumberFormat('id-Id', { style: 'currency', currency: "idr" }).format(grandTotal)}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className="mt-8 space-y-8 text-xs">
                <NoRedirectPagination current={billSelection?.current_page} links={billSelection?.links} from={billSelection?.from} to={billSelection?.to} total={billSelection?.total} update={(value) => setPage(value)} />
                <div className="flex items-center space-x-2">
                    <button type="button" onClick={handleSelect} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                        <span>{lang.select}</span>
                    </button>
                    {selectedBill.length !== 0 && (
                        <button type="button" onClick={clearSelection} className="inline-flex items-center px-4 py-3 space-x-2 transition border border-neutral-200 bg-neutral-50 rounded-xl active:hover:scale-90">
                            <span>{lang.clear}</span>
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

const SelectBill = ({ selected, onSelect, filter = {} }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSelect = (value) => {
        onSelect(value)
        closeModal()
    }

    return (
        <>
            <button onClick={openModal} className="inline-flex items-center px-6 py-3 space-x-2 text-xs text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                <PlusIcon className="w-4 h-4" stroke={1.5} />
                <span>{`${lang.add} ${lang.bills}`}</span>
            </button>
            <Modal size="6xl" isOpen={isOpen} onClose={closeModal} title={`${lang.select} ${lang.bill}`} content={<Content selected={selected} onSelect={handleSelect} filter={filter} />} />
        </>
    )
}

export default SelectBill