import { RouterProvider } from "react-router-dom"
import { PageProvider } from "contexts/page-context"
import React from "react"

import routes from "routes/web"
import Toast from "components/toast"
import nProgress from "nprogress"
import axios from "axios"
import { LoaderIcon } from "components/icons"
import { motion } from "framer-motion"

const Fallback = () => {
    return (
        <motion.div initial={{ scale: .5, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ type: "spring", damping: 20 }} className="flex items-center justify-center w-full min-h-screen">
            <LoaderIcon className="w-8 h-8 animate-spin" stroke={2} />
        </motion.div>
    )
}

function App() {
    // Add a request interceptor
    axios.interceptors.request.use(function (config) {
        // Do something before request is sent
        nProgress.start()
        return config
    }, function (error) {
        // Do something with request error
        nProgress.done()
        console.error(error)
        return Promise.reject(error)
    })

    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
        // Do something with response data
        nProgress.done()
        return response
    }, function (error) {
        // Do something with response error
        nProgress.done()
        console.error(error)
        return Promise.reject(error)
    })

    return (
        <PageProvider>
            <div id="app">
                <RouterProvider router={routes} fallbackElement={(<Fallback />)} />
            </div>
            <Toast />
        </PageProvider>
    )
}

export default App