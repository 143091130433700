import { useEffect, useState } from 'react'
import { Modal } from 'components'
import { lang } from "config"
import Add from 'pages/petty-cash/destination/add'
import { NoteIcon, XIcon } from 'components/icons'
import moment from 'moment'
import NoteTooltip from './destination/note-tooltip'
import { usePage } from 'contexts/page-context'
import ReferenceList from './destination/reference-list'

const Content = ({ currency = "idr", data = [], closeModal, onChange, enableAction }) => {
    const { configuration } = usePage()

    const [list, setList] = useState(data)

    useEffect(() => {
        onChange(list)
    }, [list])

    const handleAdd = (data) => {
        setList([...list, data])
    }

    const handleRemove = (index) => {
        const value = list.filter((row, i) => i !== index)

        setList(value)

        console.log(value)
    }

    return (
        <div className="mt-8 space-y-8">
            <div className="space-y-6">
                <div className="flex items-center justify-end text-xs">
                    {enableAction && (
                        <div>
                            <Add data={list} onSubmit={handleAdd} />
                        </div>
                    )}
                </div>
                <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                    <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                        <thead className="bg-neutral-50 rounded-t-3xl">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.destination}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Karyawan</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.amount}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.ppn}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.pph}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.subtotal}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_date}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.reference_number}</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.note}</th>
                                {enableAction && (
                                    <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                )}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-neutral-200">
                            {list.length === 0 && (
                                <tr className="text-center">
                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {lang.no_data}
                                    </td>
                                </tr>
                            )}
                            {list.length !== 0 && list?.map((row, index) => (
                                <tr key={row}>
                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        <div className="flex flex-col">
                                            <span className="font-medium text-neutral-900">{row.destination_coa ? row.destination_coa.description : row.destination.description}</span>
                                            <span>{row.destination_coa ? row.destination_coa.number : row.destination.number}</span>
                                        </div>
                                    </td>
                                    {list.length && list.find((data) => data.destination_coa ? data.destination_coa.number : (configuration('karyawan_accounts')?.includes(row.destination.number))) ? (
                                        <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                            {row.employee?.name}
                                        </td>
                                    ) : (
                                        <td></td>
                                    )}
                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {Intl.NumberFormat('id-Id', { style: 'currency', currency: currency }).format(row.amount)}
                                    </td>
                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        <span>{Intl.NumberFormat('id-Id', { style: 'currency', currency: row.currency ?? currency }).format(row.ppn)}</span>
                                        <span className="px-2 py-1 ml-2 rounded-full bg-neutral-100">{`${row.ppn_percentage ?? 0}%`}</span>
                                    </td>
                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        <span>{Intl.NumberFormat('id-Id', { style: 'currency', currency: row.currency ?? currency }).format(row.pph)}</span>
                                        <span className="px-2 py-1 ml-2 rounded-full bg-neutral-100">{`${row.pph_percentage ?? 0}%`}</span>
                                    </td>
                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {Intl.NumberFormat('id-Id', { style: 'currency', currency: currency }).format(row.subtotal)}
                                    </td>
                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        <span>{`${moment(row.transaction_date).format('MMMM D, YYYY')}`}</span>
                                        <span className="px-2 py-1 ml-2 rounded-full bg-neutral-100">{`${moment(row.transaction_date).format('HH.mm')}`}</span>
                                    </td>
                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        <ReferenceList data={row.references ?? []} />
                                    </td>
                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        <NoteTooltip position="left" data={row.note} />
                                    </td>
                                    {enableAction && (
                                        <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                            <button onClick={() => handleRemove(index)} class={`${false ? 'opacity-50 bg-red-900' : 'bg-red-800'} inline-flex items-center p-1 text-white transition rounded-full active:hover:scale-90`}>
                                                <XIcon className="w-6 h-6" />
                                            </button>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="text-xs">
                <button onClick={closeModal} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.close}</span>
                </button>
            </div>
        </div>
    )
}

const DestinationList = ({ data = [], largeButton = false, onChange, enableAction }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    return (
        <>
            {largeButton ? (
                <button type="button" onClick={openModal} className="block w-full px-2 py-2 mt-1 text-sm transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200">
                    {enableAction ? lang.select_destination : lang.see_destination} {data.length !== 0 && `(${data.length})`}
                </button>
            ) : (
                <button onClick={openModal} className="inline-flex items-center space-x-2 text-xs underline">
                    <div>
                        {lang.see_destination}
                    </div>
                </button>
            )}
            <Modal isOpen={isOpen} size="4xl" onClose={closeModal} title="List Tujuan" content={<Content data={data} closeModal={closeModal} onChange={onChange} enableAction={enableAction} />} />
        </>
    )
}

export default DestinationList