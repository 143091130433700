import { useState } from 'react'
import { Modal } from 'components'
import Label from 'components/forms/label'
import Input from 'components/forms/input'
import { lang } from 'config'
import useBranches from 'repositories/branch'
import { useEffect } from 'react'

const Content = ({ data, onSubmit, onReset }) => {
    const { data: branchSelection, isLoading: isLoadingBranch } = useBranches({
        paginate: false
    })

    const [id, setId] = useState(data.id ?? null)
    const [name, setName] = useState(data.name ?? null)
    const [branch, setBranch] = useState(data.branch ?? null)
    const [bank, setBank] = useState(data.bank ?? null)
    const [trashed, setTrashed] = useState(data.trashed ?? null)

    const handleSubmit = (e) => {
        e.preventDefault()

        const data = {
            id,
            name,
            branch,
            bank,
            trashed
        }

        // Filter before passing data to parent
        onSubmit(Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null && v != '')))
    }

    useEffect(() => {
        setBranch(data?.branch ?? null)
    }, [data])

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                    <div>
                        <Label htmlFor="id" value={lang.merchant_id} />
                        <Input onChange={e => setId(e.target.value)} value={id} id="id" />
                    </div>
                </div>
                <div>
                    <div>
                        <Label htmlFor="name" value={lang.name} />
                        <Input onChange={e => setName(e.target.value)} value={name} id="name" />
                    </div>
                </div>
                <div>
                    <Label htmlFor="branch" value={lang.branch} />
                    <select onChange={(e) => setBranch(e.target.value)} value={branch} name="branch" id="branch" className={`border-neutral-200 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                        {isLoadingBranch && (
                            <option value={null} disabled selected>{lang.loading}</option>
                        )}
                        {!isLoadingBranch && (
                            <option value={null} selected></option>
                        )}
                        {!isLoadingBranch && branchSelection.map(row => (
                            <option value={row.id}>{row.name}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <div>
                        <Label htmlFor="bank" value={lang.bank} />
                        <Input onChange={e => setBank(e.target.value)} value={bank} id="bank" />
                    </div>
                </div>
                <div>
                    <Label htmlFor="trashed" value="Trashed" />
                    <select onChange={(e) => setTrashed(e.target.value)} value={trashed} name="trashed" id="trashed" className={`border-neutral-200 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                        <option value={null} selected></option>
                        <option value="with">With Trashed</option>
                        <option value="only">Only Trashed</option>
                    </select>
                </div>
            </div>
            <div className="flex items-center space-x-2 text-xs">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.filter}</span>
                </button>
                <button type="button" onClick={onReset} className="items-center px-6 py-3 transition border border-neutral-200 bg-neutral-50 rounded-xl active:hover:scale-90">
                    <span>{lang.clear}</span>
                </button>
            </div>
        </form>
    )
}

const Filter = ({ data, onSubmit, onReset }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSubmit = (value) => {
        closeModal()
        onSubmit(value)
    }

    const handleReset = () => {
        closeModal()
        onReset()
    }

    return (
        <>
            <button onClick={openModal} className="inline-flex items-center px-4 py-3 space-x-2 transition border border-neutral-200 bg-neutral-50 rounded-xl active:hover:scale-90">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5"></path>
                </svg>
                <span>{lang.filter}</span>
                {Object.keys(data).length > 0 && (
                    <span className="inline-flex w-4 h-4 font-semibold rounded-full text-[0.5rem] justify-center text-neutral-200 bg-neutral-800">
                        {`${Object.keys(data).length}`}
                    </span>
                )}
            </button>
            <Modal isOpen={isOpen} onClose={closeModal} title={`${lang.filter} ${lang.data}`} content={<Content onSubmit={handleSubmit} onReset={handleReset} data={data} />} />
        </>
    )
}

export default Filter