import { useEffect, useState } from 'react'
import { Modal } from 'components'
import ErrorMessage from 'components/forms/error-message'
import { lang } from 'config'
import ChartOfAccountCombobox from 'components/forms/chart-of-account-combobox'
import useChartOfAccounts from 'repositories/source'
import lodash from 'lodash'
import deepdash from 'deepdash'
import InputAmount from 'components/forms/input-amount'
import useDestinationTaxMatrices from 'repositories/destination-tax-matrices'
import Checkbox from 'components/forms/checkbox'
import Radio from 'components/forms/radio'
import { PlusIcon, UsersIcon, XIcon } from 'components/icons'
import moment from 'moment'
import InputDate from 'components/forms/input-date'
import axios from 'axios'
import { toast } from 'react-hot-toast'
import nProgress from 'nprogress'
import Label from 'components/forms/label'
import useUsers from 'repositories/user'
import { usePage } from 'contexts/page-context'
import SelectBill from 'pages/petty-cash/select-bill'
import SupplierIcon from 'components/icons/supplier'

const Content = ({ data = [], currency = "idr", onSubmit }) => {
    const _ = deepdash(lodash)

    const { configuration } = usePage()

    const [destination, setDestination] = useState()
    const [employeeCode, setEmployeeCode] = useState()
    const [employee, setEmployee] = useState()
    const [amount, setAmount] = useState(0)

    const [ppn, setPpn] = useState()
    const [pph, setPph] = useState()

    const [ppnPercentage, setPpnPercentage] = useState()
    const [pphPercentage, setPphPercentage] = useState(10)

    const [ppnType, setPpnType] = useState()
    const [pphType, setPphType] = useState()

    const [showPpnInput, setShowPpnInput] = useState(false)
    const [showPphInput, setShowPphInput] = useState(false)

    const [subtotal, setSubtotal] = useState()
    const [billTotal, setBillTotal] = useState()

    const [date, setDate] = useState(moment().format('YYYY-MM-DDTHH:mm'))

    const [note, setNote] = useState()

    const [referenceNumber, setReferenceNumber] = useState([])
    const [references, setReferences] = useState([])
    const [bill, setBill] = useState([])

    const [errors, setErrors] = useState({})

    const { data: destinationTaxMatrices } = useDestinationTaxMatrices({
        destination: destination?.number
    })

    const { data: destinationSelection, isLoading: isLoadingDestination, mutate: mutateDestination } = useChartOfAccounts({
        type: 'destination'
    }, `${process.env.REACT_APP_ACCOUNTING_API_URL}/chart-of-account`)

    const { data: userSelection, isLoading: isLoadingUserSelection } = useUsers({
        paginate: false
    })

    useEffect(() => {
        if (employeeCode && !isLoadingUserSelection) {
            setEmployee(userSelection?.find((row) => row.phone == employeeCode))
        }
    }, [employeeCode, isLoadingUserSelection])

    useEffect(() => {
        var ppn = 0
        var pph = 0
        var subtotal = 0

        var referenceNumber = []
        var billTotal = 0

        var percentage = 0

        bill.map((row) => {
            referenceNumber.push({ label: "Bill Number", code: "bill_number", value: row.bill_number })

            if (row.transaction_type === 0) {
                billTotal += parseFloat(row.grand_total)
            } else {
                billTotal -= parseFloat(row.grand_total)
            }
        })

        if (showPpnInput && ppnType !== undefined) {
            percentage = destinationTaxMatrices?.find((row) => (row?.tax.type === 'ppn' && parseInt(row.transaction_type) === ppnType))?.tax.percentage

            ppn = (parseFloat(amount ?? 0) * parseFloat(percentage) / 100)

            setPpnPercentage(percentage)
        }

        if (showPphInput && pphType !== undefined && pphPercentage) {
            pph = (parseFloat(amount ?? 0) * parseFloat(pphPercentage) / 100)
        }

        subtotal = ((-amount ?? 0) + (ppnType === 0 ? ppn : (-ppn)) + (pphType === 0 ? pph : (-pph)) + billTotal)

        setReferences(bill)
        setReferenceNumber(referenceNumber)

        setPpn(ppn)
        setPph(pph)
        setSubtotal(Math.abs(subtotal))

        if (bill.length > 0 && amount !== 0) {
            setAmount(0)
        }

        setBillTotal(Math.abs(billTotal))
    }, [bill, amount, ppnType, pphType, pphPercentage])

    useEffect(() => {
        if (!showPpnInput) {
            setPpnType()
        }

        if (!showPphInput) {
            setPphType()
        }
    }, [showPpnInput, showPphInput])

    const togglePphInput = () => {
        setShowPphInput(!showPphInput)
    }

    const togglePpnInput = () => {
        setShowPpnInput(!showPpnInput)
    }

    const handleSelectBill = (value) => {
        setBill(value)
    }

    const deselectCurrentBill = (value) => {
        setBill(bill.filter((row) => row.bill_number !== value.bill_number))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        nProgress.start()

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/petty-cash/validate`, {
            destination: destination?.number,
            employee_code: employeeCode,
            is_ppn_available: showPpnInput === true ? showPpnInput : undefined,
            ppn: (showPpnInput && ppnType !== undefined ? ppn : null),
            ppn_percentage: (showPpnInput && ppnPercentage !== undefined ? ppnPercentage : null),
            ppn_type: (showPpnInput && ppnType !== undefined ? ppnType : null),
            is_pph_available: showPphInput === true ? showPphInput : undefined,
            pph: (showPphInput && pphType !== undefined ? pph : null),
            pph_percentage: (showPphInput && pphPercentage !== undefined ? pphPercentage : null),
            pph_type: (showPphInput && pphType !== undefined ? pphType : null),
            amount,
            transaction_date: date,
            subtotal,
            bill_total: billTotal,
            reference_number: referenceNumber,
            note
        }).then((response) => {
            toast.success(response.data.message)

            onSubmit({
                destination,
                destination_name: destination?.description,
                employee,
                employee_code: employeeCode,
                is_ppn_available: showPpnInput === true ? showPpnInput : undefined,
                ppn: (showPpnInput && ppnType !== undefined ? ppn : null),
                ppn_percentage: (showPpnInput && ppnPercentage !== undefined ? ppnPercentage : null),
                ppn_type: (showPpnInput && ppnType !== undefined ? ppnType : null),
                is_pph_available: showPphInput === true ? showPphInput : undefined,
                pph: (showPphInput && pphType !== undefined ? pph : null),
                pph_percentage: (showPphInput && pphPercentage !== undefined ? pphPercentage : null),
                pph_type: (showPphInput && pphType !== undefined ? pphType : null),
                amount,
                transaction_date: date,
                subtotal,
                bill_total: billTotal,
                references,
                reference_number: referenceNumber,
                note
            })

            nProgress.done()
        }).catch((error) => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            toast.error(error.response.data.message)

            console.log(error.response)

            nProgress.done()
        })
    }

    return (
        <div className="mt-8 space-y-8">
            <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-2">
                        <Label htmlFor="destination" value={lang.destination} />
                        <ChartOfAccountCombobox error={errors.destination} isLoading={isLoadingDestination} selection={destinationSelection ? _.filterDeep(destinationSelection, (row) => data.find((item) => item.destination?.number === '203.00.06') ? row.number !== '101.02.04' : (data.find((item) => item.destination?.number === '101.02.04') ? row.number !== '203.00.06' : true), { childrenPath: 'children' }) : []} onChange={(value) => setDestination(value)} value={destination} />
                        <ErrorMessage error={errors.destination} />
                    </div>

                    {destination && configuration('karyawan_accounts')?.includes(destination.number) && (
                        <>
                            <div className="col-span-2">
                                <label for="employee_code" className="block text-xs text-neutral-700">Karyawan</label>
                                <select value={employeeCode} onChange={(e) => setEmployeeCode(e.target.value)} name="employee_code" id="employee_code" className={`${errors.employee_code ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                    <option value={null} selected disabled>-- {lang.select} Karyawan --</option>
                                    {userSelection?.length !== 0 && userSelection?.map(row => (
                                        <option value={row.phone}>{row.name}</option>
                                    ))}
                                </select>
                                <ErrorMessage error={errors.employee_code} />
                            </div>

                            {(employeeCode && destination?.number === '203.00.06') && (
                                <div className="col-span-2 space-y-1">
                                    <span className="text-xs text-neutral-500">{lang.bill}</span>
                                    <div className="w-full p-4 space-y-4 border rounded-2xl">
                                        <div className={`${errors.transaction_type ? 'border-red-200' : 'border-neutral-200'} overflow-x-auto border rounded-xl`}>
                                            <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                                <thead className="border-b bg-neutral-50 rounded-t-3xl border-neutral-200">
                                                    <tr>
                                                        <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">No. Bill</th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.grand_total}</th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.customer_or_supplier}</th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.type}</th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_type}</th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.bill_date}</th>
                                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.customer_or_supplier_code}</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-neutral-200">
                                                    {/* When there are no list available */}
                                                    {bill.length === 0 && (
                                                        <tr className="text-center">
                                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                {lang.no_bill_selected}
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {bill.length > 0 && bill.map(row => (
                                                        <tr key={row.bill_number}>
                                                            <td className="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                                <div className="inline-flex items-center space-x-2">
                                                                    <button onClick={() => deselectCurrentBill(row)} class="inline-flex items-center p-1 text-white transition bg-red-800 rounded-full active:hover:scale-90">
                                                                        <XIcon className={`w-6 h-6`} />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                            <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                                {row.bill_number}
                                                            </td>
                                                            <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                                {row.currency && Intl.NumberFormat('id-Id', { style: 'currency', currency: row.currency }).format(row.grand_total)}
                                                            </td>
                                                            <td class="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                                <div className="flex items-center space-x-2">
                                                                    <span>
                                                                        {(row.type?.code === 'po' || row.type?.code === 'rt-po' || row.type?.code === 'rt-adv') ? (
                                                                            <SupplierIcon className="w-4 h-4" stroke={1.5} />
                                                                        ) : (
                                                                            <UsersIcon className="w-4 h-4" stroke={1.5} />
                                                                        )}
                                                                    </span>
                                                                    <span>
                                                                        {(row.type?.code === 'po' || row.type?.code === 'rt-po' || row.type?.code === 'rt-adv') ? row.supplier?.name : row.customer?.name}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                {row.type?.name}
                                                            </td>
                                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                {row.transaction_type === 0 ? lang.income : lang.expense}
                                                            </td>
                                                            <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                {moment(row.invoice_date).format('MMMM D, YYYY')}
                                                            </td>
                                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                                {row.payor_or_payee_code}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <SelectBill filter={{ payor_or_payee_code: employeeCode }} selected={bill} onSelect={handleSelectBill} error={false} />
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    <div className="col-span-2">
                        <Label htmlFor="note" value={lang.note} />
                        <textarea onChange={e => setNote(e.target.value)} value={note} name="note" id="note" rows="5" className="block w-full px-4 py-2 mt-1 text-sm transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200"></textarea>
                        <ErrorMessage error={errors.note} />
                    </div>

                    <div>
                        <Label htmlFor="date" value={lang.date} />
                        <InputDate minDate={moment().startOf('month').toDate()} maxDate={moment().endOf('month').toDate()} dateFormat="dd/MM/yyyy, HH:mm" showTimeSelect id="date" onChange={(value) => setDate(moment(value).format('Y-MM-DDTHH:mm'))} selected={Date.parse(date)} value={Date.parse(date)} />
                        <ErrorMessage error={errors.transaction_date} />
                    </div>
                </div>

                <div className="space-y-2">
                    <div className="w-1/2">
                        <div>
                            <label for="amount" className="block text-xs text-neutral-700">{lang.amount}</label>
                            <InputAmount disabled={bill.length > 0} isAllowed={(value) => value.floatValue >= 0 || value.floatValue === undefined} onChange={value => setAmount(value)} value={parseFloat(amount)} error={errors.amount} id="amount" />
                            <ErrorMessage error={errors.amount} />
                        </div>
                    </div>

                    {destinationTaxMatrices?.find((row) => row?.tax.type === 'ppn') && (
                        <div className="flex items-center">
                            <div className="flex items-center h-5">
                                <Checkbox id="toggle_ppn_input" checked={showPpnInput} onChange={() => togglePpnInput()} />
                            </div>
                            <div className="mt-1 ml-3 text-sm">
                                <label htmlFor="toggle_ppn_input" className="font-medium text-gray-700">
                                    {lang.ppn}
                                </label>
                            </div>
                        </div>
                    )}

                    {showPpnInput && (
                        <div className="w-1/2">
                            <Radio error={errors.ppn_type} disabled={!showPpnInput} onChange={(value) => setPpnType(value)} value={ppnType} keyValue="transaction_type" title="label" selection={destinationTaxMatrices?.filter((row) => row?.tax.code === 'ppn')} />
                            <ErrorMessage error={errors.ppn_type} />
                        </div>
                    )}

                    {destinationTaxMatrices?.find((row) => row?.tax.type === 'pph') && (
                        <div className="flex items-center">
                            <div className="flex items-center h-5">
                                <Checkbox id="toggle_pph_input" checked={showPphInput} onChange={() => togglePphInput()} />
                            </div>
                            <div className="mt-1 ml-3 text-sm">
                                <label htmlFor="toggle_pph_input" className="font-medium text-gray-700">
                                    {lang.pph23}
                                </label>
                            </div>
                        </div>
                    )}

                    {showPphInput && (
                        <div className="w-1/2 space-y-4">
                            <div>
                                <label for="pph_percentage" className="block text-xs text-neutral-700">{lang.pph_percentage}</label>
                                <InputAmount max={parseFloat(100)} onChange={value => setPphPercentage(value)} value={parseFloat(pphPercentage)} error={errors.pph_percentage} id="pph_percentage" />
                                <ErrorMessage error={errors.pph_percentage} />
                            </div>

                            <Radio error={errors.pph_type} disabled={!showPphInput} onChange={(value) => setPphType(value)} value={pphType} keyValue="transaction_type" title="label" selection={destinationTaxMatrices?.filter((row) => row?.tax.code === 'pph-23')} />
                            <ErrorMessage error={errors.pph_type} />
                        </div>
                    )}

                    <div className="space-y-4">
                        {showPpnInput && (
                            <div>
                                <p className="text-sm text-gray-800">{lang.ppn}</p>
                                <p className="text-xs text-gray-500">{currency && Intl.NumberFormat('id-Id', { style: 'currency', currency }).format(!isNaN(ppn) ? ppn : 0)}</p>
                            </div>
                        )}
                        {showPphInput && (
                            <div>
                                <p className="text-sm text-gray-800">{lang.pph}</p>
                                <p className="text-xs text-gray-500">{currency && Intl.NumberFormat('id-Id', { style: 'currency', currency }).format(!isNaN(pph) ? pph : 0)}</p>
                            </div>
                        )}
                        {(bill.length !== 0 && billTotal) && (
                            <div>
                                <p className="text-sm text-gray-800">Total Bill</p>
                                <p className="text-xs text-gray-500">{currency && Intl.NumberFormat('id-Id', { style: 'currency', currency }).format(!isNaN(billTotal) ? billTotal : 0)}</p>
                            </div>
                        )}
                        <div>
                            <p className="text-sm text-gray-800">{lang.subtotal}</p>
                            <p className="text-xs text-gray-500">{currency && Intl.NumberFormat('id-Id', { style: 'currency', currency }).format(!isNaN(subtotal) ? subtotal : 0)}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-xs">
                <button onClick={handleSubmit} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.save}</span>
                </button>
            </div>
        </div>
    )
}

const Add = ({ data = [], onSubmit }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSubmit = (data) => {
        onSubmit(data)

        closeModal()
    }

    return (
        <>
            <button onClick={openModal} className="inline-flex items-center px-4 py-3 space-x-2 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                <PlusIcon className="w-4 h-4" stroke={1.5} />
                <span>{lang.create}</span>
            </button>
            <Modal size="3xl" isOpen={isOpen} onClose={closeModal} title={`${lang.create} ${lang.data}`} content={<Content data={data} onSubmit={handleSubmit} />} />
        </>
    )
}

export default Add