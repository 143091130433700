export function pluck(array, key) {
    return array.map((data) => data[key])
}

export function matchRegex(array, search) {
    var condition = new RegExp(search)

    const result = array.filter((data) => Object.keys(data).some((key) => condition.test(key)))

    return result
}

export function matchObjectRegex(object, search) {
    var condition = new RegExp(search)

    const result = Object.keys(object).some((key) => condition.test(key))

    return result
}