import { useEffect, useLayoutEffect, useState } from "react"
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { motion } from "framer-motion"
import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import moment from "moment/moment"
import nProgress from "nprogress"
import { useAuth } from "contexts/auth-context"
import usePettyCashes from "repositories/petty-cash"
import { Pagination } from "components"
import { lang } from "config"
import Label from "components/forms/label"
import InputDate from "components/forms/input-date"
import axios from "axios"
import { upperFirst } from "lodash"
import reactElementToJSXString from "react-element-to-jsx-string"
import useReports from "repositories/report"
import { LoaderIcon } from "components/icons"
import { usePage } from "contexts/page-context"
import toast from "react-hot-toast"
import useBranches from "repositories/branch"

export default function BiayaOutletRekap({ title }) {
    // Logged in user ability
    const { user } = useAuth()
    // Page context
    const { configuration } = usePage()

    // React router navigate hook
    const navigate = useNavigate()
    // React router location hook
    const location = useLocation()
    // React router loader hook
    // const data = useLoaderData()

    const [prevRouteState, setPrevRouteState] = useState({})

    useLayoutEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }
        getPrevRouteState()
    }, [])

    // Query parameters
    const [searchParams, setSearchParams] = useSearchParams()

    const [from, setFrom] = useState(searchParams.get('from'))
    const [to, setTo] = useState(searchParams.get('to'))
    const [branch, setBranch] = useState(searchParams.get('branch_id'))

    const page = searchParams.get('page')
    const search = searchParams.get('search')

    // Report data
    const { data: reportDataLists, isLoading } = useReports('biaya-outlet', {
        page,
        search,
        branch_id: branch,
        from,
        to
    })

    // Branch selection
    const { data: branchSelection, isLoading: isLoadingBranchSelection } = useBranches({
        paginate: false
    })

    // Watch changes on petty cash data and loading state
    useEffect(() => {
        if (search === "") {
            navigate(``, { replace: true })
        }
    }, [reportDataLists])

    const downloadData = (type) => {
        axios.post(`${process.env.REACT_APP_REPORT_API_URL}/biaya-outlet/download`, {
            phone: user?.phone,
            type,
            branch_id: branch,
            from,
            to
        }).then((response) => {
            toast.success(response.data.message)
        }).catch((error) => {
            toast.error(error.response.data.message)
        })
    }

    return (
        <AppLayout title={title}>
            <div className="p-4 space-y-16 lg:p-8">
                <div>
                    {prevRouteState?.back && (
                        <button>
                            <Link to={location.state?.from} state={{ back: null, from: location.pathname, transition: 'slide' }} className="transition hover:opacity-50">
                                <motion.h3 layout transition={{ type: "spring", damping: 20 }} layoutId={prevRouteState?.back} className="text-sm">{prevRouteState?.back}</motion.h3>
                            </Link>
                        </button>
                    )}
                    <Transition>
                        <h1 className="text-3xl font-medium">
                            {title}
                        </h1>
                    </Transition>
                </div>
                <Transition>
                    <div className="space-y-6">
                        <div className="items-center justify-between space-y-4 text-xs sm:space-y-0 sm:flex">
                            <div className="items-center space-y-2 sm:space-x-2 sm:space-y-0 sm:flex">
                                {/* <div>
                                    <Label htmlFor="from" value={lang.from} />
                                    <InputDate id="from" maxDate={Date.parse(to)} onChange={(value) => setFrom(moment(value).format('Y-MM-DD'))} value={Date.parse(from)} selected={Date.parse(from)} />
                                </div>
                                <div>
                                    <Label htmlFor="to" value={lang.to} />
                                    <InputDate id="to" minDate={Date.parse(from)} onChange={(value) => setTo(moment(value).format('Y-MM-DD'))} value={Date.parse(to)} selected={Date.parse(to)} />
                                </div> */}
                                <div>
                                    <Label htmlFor="branch_id" value={lang.branch} />
                                    <select onChange={e => setBranch(e.target.value)} value={branch} name="branch" id="branch" className={`border-neutral-200 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                        <option value={null} disabled selected>-- {lang.choose} {lang.branch}  --</option>
                                        {branchSelection?.map(row => (
                                            <option value={row.id}>{row.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="flex items-center justify-between gap-2 sm:justify-normal">
                                <button onClick={() => downloadData("xlsx")} className={`bg-neutral-800 inline-flex items-center px-4 py-3 space-x-2 text-white transition rounded-xl active:hover:scale-90`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4"></path>
                                        <path d="M12 13l0 9"></path>
                                        <path d="M9 19l3 3l3 -3"></path>
                                    </svg>
                                    <span>Kirim XLSX</span>
                                </button>
                                <button onClick={() => downloadData("pdf")} className={`bg-neutral-800 inline-flex items-center px-4 py-3 space-x-2 text-white transition rounded-xl active:hover:scale-90`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4"></path>
                                        <path d="M12 13l0 9"></path>
                                        <path d="M9 19l3 3l3 -3"></path>
                                    </svg>
                                    <span>Kirim PDF</span>
                                </button>
                            </div>
                        </div>
                        <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                            <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                <thead className="bg-neutral-50 rounded-t-3xl">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">No. Perkiraan</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Cabang</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Nama Akun</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Jumlah</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-neutral-200">
                                    {/* When loading */}
                                    {isLoading && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.loading_data}
                                            </td>
                                        </tr>
                                    )}

                                    {/* When there are no list available */}
                                    {reportDataLists?.data.length === 0 && !search && !isLoading && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.no_data}
                                            </td>
                                        </tr>
                                    )}

                                    {/* When there are no list available on searching */}
                                    {reportDataLists?.data.length === 0 && search && !isLoading && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.no_result}
                                            </td>
                                        </tr>
                                    )}

                                    {reportDataLists?.data.length > 0 && reportDataLists.data.map(row => (
                                        <tr>
                                            <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                {row.account_number}
                                            </td>
                                            <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                {row.branch_name}
                                            </td>
                                            <td class="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                {row.account_name}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(row.amount)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination links={reportDataLists?.links} from={reportDataLists?.from} to={reportDataLists?.to} total={reportDataLists?.total} />
                    </div>
                </Transition>
            </div>
        </AppLayout>
    )
}