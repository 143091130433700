import { useLayoutEffect, useState } from "react"
import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom"
import { motion } from "framer-motion"
import {
    BranchIcon,
    CalendarPlusIcon,
    CalendarIcon,
    BankIcon,
    AsteriskIcon,
    ActivityIcon
} from "components/icons"

import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import Divider from "components/divider"
import moment from "moment/moment"
import { lang } from "config"
import ApprovalHistory from "components/approval-history"
import StoreIcon from "components/icons/store"

const Detail = () => {
    const id = useParams().id
    const navigate = useNavigate()
    const location = useLocation()

    const [prevRouteState, setPrevRouteState] = useState({})

    const data = useLoaderData()

    useLayoutEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                transition: location.state?.transition
            })
        }

        getPrevRouteState()
    }, [])

    return (
        <AppLayout title={id}>
            <div className="p-4 lg:p-8 space-y-16">
                <div>
                    {prevRouteState?.back && (
                        <button className="transition hover:opacity-50" onClick={() => navigate(-1)}>
                            <motion.h3 layout transition={{ type: "spring", damping: 20 }} layoutId={prevRouteState?.back} className="text-sm">{prevRouteState?.back}</motion.h3>
                        </button>
                    )}
                    <Transition type="slide" reversed>
                        <h1 className="text-3xl font-medium">
                            {id}
                        </h1>
                    </Transition>
                </div>
                <Transition type="slide" reversed>
                    <div className="space-y-6">
                        <div className="flex items-center space-x-4">
                            <div className="p-2 rounded-xl bg-neutral-100">
                                <BranchIcon className="w-6 h-6" />
                            </div>
                            <div>
                                <div className="font-medium">{data.branch?.name}</div>
                                <div className="flex items-center space-x-2 text-xs">
                                    <div>{data.branch?.contact}</div>
                                    <div className="font-light text-neutral-200">|</div>
                                    <div>{data.branch?.address}</div>
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center justify-between p-6 border border-neutral-200 rounded-xl">
                            <div className="flex space-x-2">
                                <div>
                                    <AsteriskIcon className="w-4 h-4 text-neutral-500" stroke={1.5} />
                                </div>
                                <div>
                                    <div className="text-xs text-neutral-500">
                                        {lang.merchant_id}
                                    </div>
                                    <div className="text-2xl">
                                        {data.merchant_id}
                                    </div>
                                </div>
                            </div>

                            <div className="flex space-x-2">
                                <div>
                                    <StoreIcon className="w-4 h-4 text-neutral-500" stroke={1.5} />
                                </div>
                                <div>
                                    <div className="text-xs text-neutral-500">
                                        {lang.name}
                                    </div>
                                    <div className="text-2xl">
                                        {data.name}
                                    </div>
                                </div>
                            </div>

                            <div className="flex space-x-2">
                                <div>
                                    <BankIcon className="w-4 h-4 text-neutral-500" stroke={1.5} />
                                </div>
                                <div>
                                    <div className="text-xs text-neutral-500">
                                        {lang.bank}
                                    </div>
                                    <div className="text-2xl">
                                        {data.bank}
                                    </div>
                                </div>
                            </div>

                            <div className="flex space-x-2">
                                <div>
                                    <ActivityIcon className="w-4 h-4 text-neutral-500" stroke={1.5} />
                                </div>
                                <div>
                                    <div className="text-xs text-neutral-500">
                                        {lang.status}
                                    </div>
                                    <div className="text-2xl">
                                        {data.deleted_at ? lang.statuses.deleted : lang.statuses.active}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-4 space-y-4">
                                <div className={`${false ? 'border-red-200' : 'border-neutral-200'} overflow-x-auto border rounded-xl`}>
                                    <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                        <thead className="bg-neutral-50 rounded-t-3xl">
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.terminal_id}</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.account_number}</th>
                                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.features}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-neutral-200">
                                            {data.terminals.map((row) => (
                                                <tr key={row.id}>
                                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                        {row.terminal_id}
                                                    </td>
                                                    <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {row.account_number}
                                                    </td>
                                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                        {row.features.map((feature, index) => (<>{`${feature.name}${row.features.length === index + 1 ? '' : ', '}`}</>))}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="col-span-2 p-6 space-y-6 text-xs border rounded-xl border-neutral-200">
                                {/* Timestamp */}
                                <Divider content="Timestamp" className="font-medium" />
                                <div className="flex space-x-2">
                                    <div>
                                        <CalendarPlusIcon className="w-4 h-4" stroke={1.5} />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="font-medium">{lang.created_at}</div>
                                        <div>{moment(data.created_at).format('MMMM DD, YYYY')}</div>
                                    </div>
                                </div>

                                <div className="flex space-x-2">
                                    <div>
                                        <CalendarIcon className="w-4 h-4" stroke={1.5} />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="font-medium">{lang.updated_at}</div>
                                        <div>{moment(data.updated_at).format('MMMM DD, YYYY')}</div>
                                    </div>
                                </div>

                                {data.deleted_at && (
                                    <div className="flex space-x-2">
                                        <div>
                                            <CalendarIcon className="w-4 h-4" stroke={1.5} />
                                        </div>
                                        <div className="space-y-2">
                                            <div className="font-medium">{lang.updated_at}</div>
                                            <div>{moment(data.deleted_at).format('MMMM DD, YYYY')}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </AppLayout>
    )
}

export default Detail