import { useState } from 'react'
import { Modal } from 'components'
import axios from 'axios'
import nProgress from 'nprogress'
import Form from 'pages/merchant/terminal/form'
import toast from 'react-hot-toast'
import { lang } from 'config'
import { PlusIcon } from 'components/icons'

const Content = ({ onSubmit }) => {
    const [errors, setErrors] = useState({})

    const handleSubmit = (data) => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/merchant/terminal/validate`, data).then((response) => {
            onSubmit(data)
            toast.success(response.data.message)
        }).catch((error) => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            toast.error(error.response.data.message)
            console.log(error.response)
        })
    }

    return (
        <Form errors={errors} onSubmit={handleSubmit} />
    )
}

const Create = ({ onSubmit, error }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSubmit = (data) => {
        closeModal()
        onSubmit(data)
    }

    return (
        <>
            <button type="button" onClick={openModal} className={`${error ? 'border-red-200' : 'border-neutral-200'} inline-flex items-center px-4 py-3 space-x-2 text-xs transition border bg-neutral-50 rounded-xl active:hover:scale-90`}>
                <PlusIcon className="w-4 h-4" />
                <span>{`${lang.create}`}</span>
            </button>
            <Modal isOpen={isOpen} onClose={closeModal} title={`${lang.create} ${lang.terminal}`} content={<Content onSubmit={handleSubmit} />} />
        </>
    )
}

export default Create