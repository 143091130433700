import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { ChevronDownIcon, LogoutIcon, UserEditIcon } from 'components/icons'
import { useAuth } from 'contexts/auth-context'
import { Link, useNavigate } from 'react-router-dom'

export default function UserDropdown() {
    const navigate = useNavigate()
    const { user, logout } = useAuth()

    const handleLogout = () => {
        logout()
        navigate(0, { replace: true })
    }

    return (
        <Menu as="div" className="relative">
            <Menu.Button className="flex items-center space-x-2">
                <div className="overflow-hidden rounded-full w-9 h-9 bg-neutral-300">
                    <img src={`https://ui-avatars.com/api/?name=${user?.name}&format=svg&background=262626&color=f5f5f5`} alt="" />
                </div>
                <ChevronDownIcon className="w-4 h-4" />
            </Menu.Button>
            <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95" >
                <Menu.Items className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white border divide-y shadow-lg divide-neutral-100 rounded-xl focus:outline-none">
                    {/* <div className="px-1 py-1 ">
                            <Menu.Item>
                                {({ active }) => (
                                    <button className={`${active ? 'bg-neutral-500 text-white' : 'text-neutral-900'} group flex w-full items-center rounded-lg px-2 py-2 text-sm`}>
                                        Icon here
                                        Edit
                                    </button>
                                )}
                            </Menu.Item>
                        </div> */}
                    <div className="px-1 py-1">
                        <span className="px-2 text-xs text-neutral-500">Profile</span>
                    </div>
                    <div className="px-1 py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <Link to="/profile" className={`${active ? 'bg-neutral-100 text-neutral-900' : 'text-neutral-900'} group flex w-full items-center rounded-lg px-2 py-2 text-sm relative`}>
                                    <span>Edit Profile</span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                                        <div className={`${active ? "bg-neutral-600 text-white" : "border border-neutral-300 text-neutral-900"} w-6 h-6 flex items-center justify-center rounded-full`}>
                                            <UserEditIcon className="w-4 h-4" stroke={1.5} />
                                        </div>
                                    </span>
                                </Link>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button onClick={() => handleLogout()} className={`${active ? 'bg-neutral-100 text-neutral-900' : 'text-neutral-900'} group flex w-full items-center rounded-lg px-2 py-2 text-sm relative`}>
                                    <span>Logout</span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                                        <div className={`${active ? "bg-neutral-600 text-white" : "border border-neutral-300 text-neutral-900"} w-6 h-6 flex items-center justify-center rounded-full`}>
                                            <LogoutIcon className="w-4 h-4" stroke={1.5} />
                                        </div>
                                    </span>
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}