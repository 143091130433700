import { Modal } from 'components'
import moment from 'moment/moment'
import { lang } from "config"
import PaymentDetail from 'pages/verify-payment/payment-detail'

const Content = ({ data, closeModal }) => {
    return (
        <div className="mt-8 space-y-8">
            <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                    <thead className="bg-neutral-50 rounded-t-3xl">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.order}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.branch}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.customer_number}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.customer_name}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.vehicle_number}</th>
                            {/* <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.status}</th> */}
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.grand_total}</th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.order_date}</th>
                            <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-neutral-200">
                        {data.map(row => (
                            <tr key={row.order_id}>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.order_id}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.branch_name}
                                </td>
                                <td class="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.customer_number}
                                </td>
                                <td class="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.customer_name}
                                </td>
                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                    {row.vehicle_number}
                                </td>
                                {/* <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    <span className={`${row.status_order === 1 ? "text-neutral-800 bg-neutral-100" : (row.status_order === 2 ? "text-yellow-800 bg-yellow-100" : (row.status_order === 3 ? "text-yellow-800 bg-yellow-100" : (row.status_order === 4 ? "text-blue-800 bg-blue-100" : (row.status_order === 5 ? "text-green-800 bg-green-100" : (row.status_order === 6 ? "text-neutral-800 bg-neutral-100" : (row.status_order === 7 ? "text-neutral-800 bg-neutral-100" : (row.status_order === 8 ? "text-red-800 bg-red-100" : "text-neutral-800 bg-neutral-100")))))))} inline-flex px-2 text-xs font-semibold leading-5 rounded-full`}>
                                        {`${row.status_order === 1 ? "Menunggu Pembayaran" : (row.status_order === 2 ? "Menunggu Konfirmasi" : (row.status_order === 3 ? "Menunggu Diproses" : (row.status_order === 4 ? "Diproses" : (row.status_order === 5 ? "Selesai" : (row.status_order === 6 ? "Menunggu Pembayaran" : (row.status_order === 7 ? "Dimintai Approval" : (row.status_order === 8 ? "Canceled" : "Uncreated")))))))}`}
                                    </span>
                                </td> */}
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(row.grand_total)}
                                </td>
                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                    {moment(row.order_date).format('MMMM D, YYYY')}
                                </td>
                                <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                    <div class="inline-flex items-center space-x-2">
                                        <PaymentDetail data={row} onSuccess={() => { }} />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="text-xs">
                <button onClick={closeModal} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.close}</span>
                </button>
            </div>
        </div>
    )
}

const OrderList = ({ data, isOpen = false, setIsOpen }) => {
    const closeModal = () => setIsOpen(false)

    return (
        <>
            <Modal isOpen={isOpen} size="6xl" onClose={closeModal} title={lang.order_list} content={<Content data={data} closeModal={closeModal} />} />
        </>
    )
}

export default OrderList