import { useEffect } from "react"
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { motion } from "framer-motion"
import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import moment from "moment/moment"
import Filter from "pages/merchant/filter"
import nProgress from "nprogress"
import { Pagination } from "components"
import { lang } from "config"
import useMerchants from "repositories/merchant"
import { EyeIcon, PencilIcon, PlusIcon } from "components/icons"
import { Delete, Restore } from "pages/merchant"
import DeletedTooltip from "pages/merchant/deleted-tooltip"
import { useAuth } from "contexts/auth-context"
import useMerchantFees from "repositories/merchant-fee"

const List = ({ title }) => {
    const { can } = useAuth()

    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const location = useLocation()

    const page = searchParams.get('page')
    const search = searchParams.get('search')
    const id = searchParams.get('id')
    const name = searchParams.get('name')
    const bank = searchParams.get('bank')
    const branch = searchParams.get('branch')
    const trashed = searchParams.get('trashed')

    const { data: merchantFees, isLoading, mutate } = useMerchantFees({
        page,
        search
    })

    // Watch changes on data map data and loading state
    useEffect(() => {
        if (search === "") {
            navigate(``, { replace: true })
        }

        if (isLoading) {
            nProgress.start()
        } else {
            nProgress.done()
        }
    }, [merchantFees, isLoading])

    // Data filter handlers
    const updateFilter = (value) => {
        setSearchParams(value)
    }

    const removeFilter = () => {
        setSearchParams({})
    }

    return (
        <AppLayout title={title}>
            <div className="p-4 space-y-16 lg:p-8">
                <div>
                    <button className="cursor-default">
                        <motion.h1 layout transition={{ type: "spring", damping: 20 }} layoutId={title} className="text-3xl font-medium">
                            {title}
                        </motion.h1>
                    </button>
                </div>
                <Transition>
                    <div className="space-y-6">
                        <div className="flex items-center justify-between text-xs">
                            <div className="flex items-center sm:space-x-2">
                                <div className="relative hidden sm:block">
                                    <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <circle cx={10} cy={10} r={7}></circle>
                                            <line x1={21} y1={21} x2={15} y2={15}></line>
                                        </svg>
                                    </div>
                                    <input onChange={(e) => setSearchParams({ search: e.target.value })} value={searchParams.get('search')} type="text" placeholder={`${lang.search} Merchant`} autoComplete="off" className="w-64 py-3 pl-8 pr-4 text-xs transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200" />
                                </div>
                                <Filter onSubmit={updateFilter} onReset={removeFilter} data={Object.fromEntries(Object.entries({ id, name, bank, branch, trashed }).filter(([_, v]) => v != null))} />
                            </div>
                            <div>
                                {can('merchant.create') && (
                                    <Link to="create" state={{ back: title, from: location?.pathname, transition: 'slide' }} className="inline-flex items-center px-4 py-3 space-x-2 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                                        <PlusIcon className="w-4 h-4" stroke={1.5} />
                                        <span>{lang.create}</span>
                                    </Link>
                                )}
                            </div>
                        </div>
                        <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                            <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                <thead className="bg-neutral-50 rounded-t-3xl">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.payment_method}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.bank}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.value}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.created_at}</th>
                                        <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-neutral-200">
                                    {/* When loading */}
                                    {isLoading && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.loading_data}
                                            </td>
                                        </tr>
                                    )}

                                    {/* When there are no list available */}
                                    {merchantFees?.data.length === 0 && !search && !isLoading && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.no_data}
                                            </td>
                                        </tr>
                                    )}

                                    {/* When there are no list available on searching */}
                                    {merchantFees?.data.length === 0 && search && !isLoading && (
                                        <tr className="text-center">
                                            <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.no_result}
                                            </td>
                                        </tr>
                                    )}

                                    {merchantFees?.data.length > 0 && merchantFees.data.map((row) => (
                                        <tr key="">
                                            <td class="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                {row.method?.name}
                                            </td>
                                            <td class="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                {row.bank?.name}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {`${row.value}${row.type === 1 ? '%' : ''}`}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {moment(row.created_at).format('MMMM D, YYYY')}
                                            </td>
                                            <td class="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                <div class="inline-flex items-center space-x-2">
                                                    {/* {row.deleted_at ? (
                                                        <button disabled className="inline-flex items-center p-1 text-white transition rounded-full opacity-50 bg-neutral-900 active:hover:scale-90">
                                                            <EyeIcon className="w-6 h-6" />
                                                        </button>
                                                    ) : (
                                                        <Link to={`${row.merchant_id}`} state={{ back: title, from: location.pathname, transition: 'slide' }} class="inline-flex items-center p-1 text-white transition bg-neutral-800 rounded-full active:hover:scale-90">
                                                            <EyeIcon className="w-6 h-6" />
                                                        </Link>
                                                    )} */}

                                                    {can('merchant.update') && (
                                                        <>
                                                            {row.deleted_at ? (
                                                                <button disabled className="inline-flex items-center p-1 text-white transition rounded-full opacity-50 bg-neutral-900 active:hover:scale-90">
                                                                    <PencilIcon className="w-6 h-6" />
                                                                </button>
                                                            ) : (
                                                                <Link to={`${row.merchant_id}/edit`} state={{ back: title, from: location.pathname, transition: 'slide' }} class="inline-flex items-center p-1 text-white transition bg-neutral-800 rounded-full active:hover:scale-90">
                                                                    <PencilIcon className="w-6 h-6" />
                                                                </Link>
                                                            )}
                                                        </>
                                                    )}

                                                    {row.deleted_at ? (
                                                        <>
                                                            {can('merchant.restore') && (
                                                                <Restore data={row} onSuccess={mutate} />
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {can('merchant.delete') && (
                                                                <Delete data={row} onSuccess={mutate} />
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* <Pagination links={merchants?.links} from={merchants?.from} to={merchants?.to} total={merchants?.total} /> */}
                    </div>
                </Transition>
            </div>
        </AppLayout>
    )
}

export default List