import { AlertTriangleIcon } from "components/icons"
import Tooltip from "components/tooltip"
import { useState } from "react"


const ErrorTooltip = ({ error, position = "left" }) => {
    const [reference, setReference] = useState()
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className="flex items-center font-normal">
            <Tooltip showOverlay={false} onClose={() => setIsOpen(false)} position={position} isOpen={isOpen} reference={reference}>
                <span className="flex max-w-lg px-4 py-2 mr-2 space-x-2 text-xs text-red-500 bg-red-100 rounded-2xl">
                    <div className="flex flex-wrap w-full whitespace-normal">
                        {error}
                    </div>
                </span>
            </Tooltip>
            {error && (
                <span ref={setReference} onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)} className="inline-flex items-center text-red-500 transition active:hover:scale-90">
                    <AlertTriangleIcon className="w-4 h-4" stroke={2} />
                </span>
            )}
        </div>
    )
}

export default ErrorTooltip