import { useEffect, useState } from "react"
import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom"
import { motion } from "framer-motion"
import {
    BranchIcon,
    TrendingUpIcon,
    ArrowsDoubleNeSwIcon,
    DollarIcon,
    CalendarPlusIcon,
    CalendarIcon,
    ReceiptIcon,
    NotesIcon,
    MailIcon,
    PhoneIcon,
    UserCircleIcon,
    AsteriskIcon
} from "components/icons"

import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import Divider from "components/divider"
import moment from "moment/moment"
import InvoiceList from "./invoice-list"
import { lang } from "config"
import ApprovalHistory from "components/approval-history"
import { usePage } from "contexts/page-context"

const TransactionDetail = () => {
    const number = useParams().number
    const navigate = useNavigate()
    const location = useLocation()

    const { configuration } = usePage()

    const [prevRouteState, setPrevRouteState] = useState({})
    const data = useLoaderData()

    useEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }
        getPrevRouteState()
    }, [])

    return (
        <AppLayout title={number}>
            <div className="p-4 space-y-16 lg:p-8">
                <div>
                    {prevRouteState?.back && (
                        <button className="transition hover:opacity-50" onClick={() => navigate(-1, { state: { transition: "slide" } })}>
                            <motion.h3 layout transition={{ duration: .5, type: "tween" }} layoutId={prevRouteState?.back} className="text-sm">{prevRouteState?.back}</motion.h3>
                        </button>
                    )}
                    <Transition type="slide" reversed>
                        <h1 className="text-3xl font-medium">
                            {number}
                        </h1>
                    </Transition>
                </div>
                <Transition type="slide" reversed>
                    <div className="space-y-6">
                        <div className="flex items-center space-x-4">
                            <div className="p-2 rounded-xl bg-neutral-100">
                                <BranchIcon className="w-6 h-6" />
                            </div>
                            <div>
                                <div className="font-medium">{data.branch?.name}</div>
                                <div className="flex items-center space-x-2 text-xs">
                                    <div>{data.branch?.contact}</div>
                                    <div className="font-light text-neutral-200">|</div>
                                    <div>{data.branch?.address}</div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-between p-6 border border-neutral-200 rounded-xl">
                            <div className="flex space-x-2">
                                <div>
                                    <ReceiptIcon className="w-4 h-4 text-neutral-500" stroke={1.5} />
                                </div>
                                <div>
                                    <div className="text-xs text-neutral-500">
                                        {lang.type}
                                    </div>
                                    <div className="text-2xl capitalize">
                                        {data.type.name}
                                    </div>
                                </div>
                            </div>

                            <div className="flex space-x-2">
                                <div>
                                    <ArrowsDoubleNeSwIcon className="w-4 h-4 text-neutral-500" stroke={1.5} />
                                </div>
                                <div>
                                    <div className="text-xs text-neutral-500">
                                        {lang.transaction_type}
                                    </div>
                                    <div className="text-2xl">
                                        {data?.transaction_type === 0 ? lang.income : lang.expense}
                                    </div>
                                </div>
                            </div>

                            <div className="flex space-x-2">
                                <div>
                                    <CalendarIcon className="w-4 h-4 text-neutral-500" stroke={1.5} />
                                </div>
                                <div>
                                    <div className="text-xs text-neutral-500">
                                        {lang.transaction_date}
                                    </div>
                                    <div className="text-2xl">
                                        {moment(data?.transaction_date).format('MMMM DD, YYYY')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <div className="w-1/2 p-6 text-sm text-neutral-100 topography bg-neutral-800 rounded-xl">
                                <div className="flex items-center justify-between pb-2">
                                    <div>{lang.payment_method}</div>
                                    <div>{data.account.method.name}</div>
                                </div>
                                {data.account.payment_type === 'edc' && (
                                    <div className="flex items-center justify-between py-2">
                                        <div>{lang.merchant_id}</div>
                                        <div>{data.account.merchant_id}</div>
                                    </div>
                                )}
                                <div className="flex items-center justify-between py-2">
                                    <div>{lang.account}</div>
                                    {data.account?.chart_of_account?.description ? (
                                        <div>{`${data.account?.chart_of_account?.description} (${data.account.coa_number})`}</div>
                                    ) : (
                                        <div>{data.account.coa_number}</div>
                                    )}
                                </div>
                                {data.account.payment_type !== 'cash' && (
                                    <div className="flex items-center justify-between py-2">
                                        <div>{lang.account_number}</div>
                                        <div>{data.account.account_number}</div>
                                    </div>
                                )}
                                {data.account.payment_type === 'giro' && (
                                    <div className="flex items-center justify-between py-2">
                                        <div>{lang.giro_due_date}</div>
                                        <div>{moment(data.account.giro_due_date).format('MMMM DD, YYYY')}</div>
                                    </div>
                                )}
                                {data.advance_payment ? (
                                    <div className="flex items-center justify-between py-2">
                                        <div>{lang.advance_payment}</div>
                                        <div>{data?.currency && Intl.NumberFormat('id-Id', { style: 'currency', currency: data?.currency }).format(data?.advance_payment)}</div>
                                    </div>
                                ) : <></>}
                                <div className="flex items-center justify-between pt-2 pb-4">
                                    <div>{lang.payment_amount}</div>
                                    <div>{data?.currency && Intl.NumberFormat('id-Id', { style: 'currency', currency: data?.currency }).format(data?.account.amount)}</div>
                                </div>
                                <div className="flex items-center justify-between pt-4 text-lg font-semibold border-t border-neutral-600">
                                    <div>{lang.grand_total}</div>
                                    <div>{data?.currency && Intl.NumberFormat('id-Id', { style: 'currency', currency: data?.currency }).format(data?.grand_total)}</div>
                                </div>
                            </div>
                            <div className="space-y-4">
                                <div className="space-y-2">
                                    <div className="font-medium">
                                        {lang.status}
                                    </div>
                                    <span className={`${configuration('status').find((row) => row.code === data.status)?.class ?? configuration('status').find((row) => row.code === 'default')?.class} inline-flex px-2 text-xs font-semibold leading-5 rounded-full`}>
                                        {configuration('status').find((row) => row.code === data.status)?.label ?? configuration('status').find((row) => row.code === 'default')?.label}
                                    </span>
                                </div>
                                <div className="space-y-2">
                                    <div className="font-medium">
                                        {lang.invoices}
                                    </div>
                                    <InvoiceList data={data.details} />
                                </div>
                                <div className="space-y-2">
                                    <div className="font-medium">
                                        {lang.approval_histories}
                                    </div>
                                    <ApprovalHistory data={data.approval_histories} />
                                </div>
                            </div>
                        </div>
                        <div className="p-6 space-y-6 text-xs border rounded-xl border-neutral-200">
                            {/* Currency */}
                            <Divider content={lang.currency_detail} className="font-medium" />
                            <div className="flex space-x-2">
                                <div>
                                    <DollarIcon className="w-4 h-4" stroke={1.5} />
                                </div>
                                <div className="space-y-2">
                                    <div className="font-medium">{lang.currency}</div>
                                    <div>{data?.currency}</div>
                                </div>
                            </div>
                            <div className="flex space-x-2">
                                <div>
                                    <TrendingUpIcon className="w-4 h-4" stroke={1.5} />
                                </div>
                                <div className="space-y-2">
                                    <div className="font-medium">{lang.exchange_rate}</div>
                                    <div>{data?.rate}</div>
                                </div>
                            </div>

                            {/* Payor or Payee */}
                            <Divider content={(data.type.code === 'po' || data.type.code === 'rt-po' || data.type.code === 'rt-adv') ? lang.supplier_detail : lang.customer_detail} className="font-medium" />
                            <div className="flex space-x-2">
                                <div>
                                    <AsteriskIcon className="w-4 h-4" stroke={1.5} />
                                </div>
                                <div className="space-y-2">
                                    <div className="font-medium">{lang.code}</div>
                                    <div>{data.payor_or_payee_code}</div>
                                </div>
                            </div>
                            <div className="flex space-x-2">
                                <div>
                                    <UserCircleIcon className="w-4 h-4" stroke={1.5} />
                                </div>
                                <div className="space-y-2">
                                    <div className="font-medium">{lang.name}</div>
                                    <div>{(data.type.code === 'po' || data.type.code === 'rt-po' || data.type.code === 'rt-adv') ? data.supplier.name : data.customer.name}</div>
                                </div>
                            </div>
                            {((data.type.code === 'po' || data.type.code === 'rt-po' || data.type.code === 'rt-adv') ? data.supplier.email : data.customer.email) && (
                                <div className="flex space-x-2">
                                    <div>
                                        <MailIcon className="w-4 h-4" stroke={1.5} />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="font-medium">{lang.email}</div>
                                        <div>{(data.type.code === 'po' || data.type.code === 'rt-po' || data.type.code === 'rt-adv') ? data.supplier.email : data.customer.email}</div>
                                    </div>
                                </div>
                            )}

                            {/* Timestamp */}
                            <Divider content="Timestamp" className="font-medium" />
                            <div className="flex space-x-2">
                                <div>
                                    <CalendarPlusIcon className="w-4 h-4" stroke={1.5} />
                                </div>
                                <div className="space-y-2">
                                    <div className="font-medium">{lang.created_at}</div>
                                    <div>{moment(data?.created_at).format('MMMM DD, YYYY')}</div>
                                </div>
                            </div>
                            <div className="flex space-x-2">
                                <div>
                                    <CalendarPlusIcon className="w-4 h-4" stroke={1.5} />
                                </div>
                                <div className="space-y-2">
                                    <div className="font-medium">{lang.created_by}</div>
                                    <div>{data?.created_by}</div>
                                </div>
                            </div>
                            {data?.updated_by && (
                                <>
                                    <div className="flex space-x-2">
                                        <div>
                                            <CalendarIcon className="w-4 h-4" stroke={1.5} />
                                        </div>
                                        <div className="space-y-2">
                                            <div className="font-medium">{lang.updated_at}</div>
                                            <div>{moment(data?.updated_at).format('MMMM DD, YYYY')}</div>
                                        </div>
                                    </div>
                                    <div className="flex space-x-2">
                                        <div>
                                            <CalendarIcon className="w-4 h-4" stroke={1.5} />
                                        </div>
                                        <div className="space-y-2">
                                            <div className="font-medium">{lang.updated_by}</div>
                                            <div>{data?.updated_by}</div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {/* Note */}
                            {data?.note && (
                                <>
                                    <Divider content={lang.note} className="font-medium" />
                                    <div className="flex space-x-2">
                                        <div>
                                            <NotesIcon className="w-4 h-4" stroke={1.5} />
                                        </div>
                                        <div className="space-y-2">
                                            <div className="font-medium">{lang.note}</div>
                                            <div>
                                                {data?.note}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </Transition>
            </div>
        </AppLayout>
    )
}

export default TransactionDetail