import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { motion } from "framer-motion"

import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import axios from "axios"
import { useAuth } from "contexts/auth-context"
import Form from "./form"
import { useSWRConfig } from "swr"
import nprogress from "nprogress"
import toast from "react-hot-toast"

const CreateAdvancePayment = ({ title }) => {
    const { user, isSuper } = useAuth()
    const { cache } = useSWRConfig()

    const location = useLocation()
    const navigate = useNavigate()

    const [prevRouteState, setPrevRouteState] = useState({})

    const [errors, setErrors] = useState({})

    useEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }

        getPrevRouteState()
    }, [])

    const handleSubmit = (data) => {
        nprogress.start()

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/advance/pay`, {
            branch_id: data.branch_id,
            payor_or_payee_code: data.payor_or_payee_code,
            reference_number: data.reference_number,
            currency: data.currency,
            rate: data.rate,
            ppn: data.ppn,
            ppn_percentage: data.ppn_percentage,
            tax_invoice_number: data.tax_invoice_number,
            amount: data.amount,
            subtotal: data.subtotal,
            max: data.max,
            note: data.note,
            transaction_date: data.transaction_date,
            transaction_type: data.transaction_type,
            created_by: user?.name,
            no_approve: isSuper() ? 1 : 0
        }).then((response) => {
            cache.clear()
            navigate({ pathname: '/advance-payment' })
            toast.success(response.data.message)
        }).catch(error => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            nprogress.done()
            toast.error(error.response.data.message)
            console.log(error.response)
        })
    }

    return (
        <AppLayout title={title}>
            <div className="p-4 lg:p-8 space-y-16">
                <div>
                    {prevRouteState?.back && (
                        <button className="transition hover:opacity-50" onClick={() => navigate(-1, { state: { transition: "slide" } })}>
                            <motion.h3 layout transition={{ duration: .5, type: "tween" }} layoutId={prevRouteState?.back} className="text-sm">{prevRouteState?.back}</motion.h3>
                        </button>
                    )}
                    <Transition type="slide" reversed>
                        <h1 className="text-3xl font-medium">
                            {title}
                        </h1>
                    </Transition>
                </div>
                <Transition type="slide" reversed>
                    <Form onSubmit={handleSubmit} errors={errors} />
                </Transition>
            </div>
        </AppLayout>
    )
}

export default CreateAdvancePayment