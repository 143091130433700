import { useEffect, useState } from "react"
import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom"
import { motion } from "framer-motion"

import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import axios from "axios"
import { useAuth } from "contexts/auth-context"
import Form from "./form"
import { useSWRConfig } from "swr"
import nprogress from "nprogress"
import toast from "react-hot-toast"
import { lang } from "config"

const EditAdvancePayment = (props) => {
    const { user } = useAuth()
    const { cache } = useSWRConfig()

    const location = useLocation()
    const navigate = useNavigate()

    const number = useParams().number

    const [prevRouteState, setPrevRouteState] = useState({})

    const data = useLoaderData()

    const [errors, setErrors] = useState({})

    useEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }

        getPrevRouteState()
    }, [])

    const handleSubmit = (data) => {
        nprogress.start()

        axios.patch(`${process.env.REACT_APP_BACKEND_URL}/advance/update`, {
            transaction_number: number,
            tax_invoice_number: data.tax_invoice_number,
            note: data.note,
            updated_by: user?.name
        }).then((response) => {
            cache.clear()
            navigate({ pathname: '/advance-payment' })
            toast.success(response.data.message)
        }).catch(error => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            nprogress.done()
            toast.error(error.response.data.message)
            console.log(error.response)
        })
    }

    return (
        <AppLayout title={`${lang.edit} ${number}`}>
            <div className="p-4 lg:p-8 space-y-16">
                <div>
                    {prevRouteState?.back && (
                        <button className="transition hover:opacity-50" onClick={() => navigate(-1, { state: { transition: "slide" } })}>
                            <motion.h3 layout transition={{ duration: .5, type: "tween" }} layoutId={prevRouteState?.back} className="text-sm">{prevRouteState?.back}</motion.h3>
                        </button>
                    )}
                    <Transition type="slide" reversed>
                        <h1 className="text-3xl font-medium">
                            {lang.edit} {number}
                        </h1>
                    </Transition>
                </div>
                <Transition type="slide" reversed>
                    <Form data={data} onSubmit={handleSubmit} errors={errors} />
                </Transition>
            </div>
        </AppLayout>
    )
}

export default EditAdvancePayment