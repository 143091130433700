import { useEffect, useState } from 'react'
import { Modal } from 'components'
import axios from 'axios'
import ErrorMessage from 'components/forms/error-message'
import Input from 'components/forms/input'
import Label from 'components/forms/label'
import nProgress from 'nprogress'
import toast from 'react-hot-toast'
import { lang } from 'config'
import { PencilIcon } from 'components/icons'
import { useAuth } from 'contexts/auth-context'
import useCustomers from 'repositories/customer'

const Content = ({ data, success }) => {
    const { user } = useAuth()

    const { data: customerSelection, isLoading: isLoadingCustomerSelection } = useCustomers({
        paginate: false
    })

    const [customer, setCustomer] = useState(data?.payor_or_payee_code)

    const [payorOrPayee, setPayorOrPayee] = useState()

    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (customer) {
            setPayorOrPayee({
                type: 'customer',
                code: customer
            })
        } else {
            setPayorOrPayee()
        }
    }, [customer])

    const handleSubmit = (e) => {
        e.preventDefault()
        nProgress.start()

        axios.patch(`${process.env.REACT_APP_BACKEND_URL}/bill/update`, {
            bill_number: data.bill_number,
            payor_or_payee: payorOrPayee,
            updated_by: user?.name
        }).then((response) => {
            success()
            toast.success(response.data.message)
        }).catch(error => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            toast.error(error.response.data.message)
            console.log(error.response)
            nProgress.done()
        })
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <Label htmlFor="customer" value={lang.customer} />
                    <select onChange={(e) => setCustomer(e.target.value)} name="customer" id="customer" className={`${errors.payor_or_payee_code ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                        <option value={null} selected disabled>-- {lang.choose} {lang.customer} --</option>
                        {customerSelection?.length !== 0 && customerSelection?.map(row => (
                            <option value={row.customer_code}>{row.name}</option>
                        ))}
                    </select>
                    <ErrorMessage error={errors.payor_or_payee_code} />
                </div>
            </div>
            <div className="text-xs">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.update}</span>
                </button>
            </div>
        </form>
    )
}

const EditPayTo = ({ data, onSuccess }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSuccess = () => {
        closeModal()
        onSuccess()
    }

    return (
        <>
            <button disabled={data.type !== 'claim'} onClick={openModal} className={`${data.type !== 'claim' ? 'opacity-50' : ''} inline-flex items-center p-1 text-white transition rounded-full bg-neutral-800 active:hover:scale-90`}>
                <PencilIcon className="w-5 h-5" stroke={1.5} />
            </button>
            <Modal isOpen={isOpen} onClose={closeModal} title={`${lang.edit} ${lang.data}`} content={<Content data={data} success={handleSuccess} />} />
        </>
    )
}

export default EditPayTo