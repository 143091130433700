import { useEffect, useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { motion } from "framer-motion"
import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import axios from 'axios'
import moment from "moment/moment"
import Edit from "pages/user/edit"
import Delete from "pages/user/delete"
import Filter from "pages/user/filter"
import nProgress from "nprogress"
import { Pagination } from "components"
import { lang } from "config"

const Customer = (props) => {
    const [customers, setCustomers] = useState({ data: [] })
    const [searchParams, setSearchParams] = useSearchParams()

    const [trigger, setTrigger] = useState(false)

    const navigate = useNavigate()

    const page = searchParams.get('page')
    const search = searchParams.get('search')
    const name = searchParams.get('name')
    const phone = searchParams.get('phone')
    const from = searchParams.get('from')
    const to = searchParams.get('to')

    useEffect(() => {
        if (search === "") {
            navigate(``, { replace: true })
        }

        nProgress.start()

        const fetch = async () => {
            await axios.get(`${process.env.REACT_APP_BACKEND_URL}/customer`, {
                params: {
                    page,
                    search,
                    name,
                    phone,
                    from,
                    to
                }
            }).then((res) => {
                setCustomers(res.data)
            }).then(() => {
                nProgress.done()
            })
        }
        fetch()
    }, [searchParams, trigger])

    const handleSuccess = () => {
        setTrigger(!trigger)
    }

    const updateFilter = (value) => {
        setSearchParams(value)
    }

    const resetFilter = () => {
        setSearchParams({})
    }

    return (
        <AppLayout title={props.title}>
            <div className="p-4 lg:p-8 space-y-16">
                <div>
                    <button className="cursor-default">
                        <motion.h1 layout transition={{ duration: .5, type: "tween" }} layoutId={props.title} className="text-3xl font-medium">
                            {props.title}
                        </motion.h1>
                    </button>
                </div>
                <Transition>
                    <div className="space-y-6">
                        <div className="flex items-center justify-between text-xs">
                            <div className="flex items-center space-x-2">
                                <div className="relative">
                                    <div className="absolute inset-y-0 flex items-center pl-3 pointer-events-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <circle cx={10} cy={10} r={7}></circle>
                                            <line x1={21} y1={21} x2={15} y2={15}></line>
                                        </svg>
                                    </div>
                                    <input onChange={(e) => setSearchParams({ search: e.target.value })} value={searchParams.get('search')} type="text" placeholder={`${lang.search} ${lang.customer}`} autoComplete="off" className="w-64 py-3 pl-8 pr-4 text-xs transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200" />
                                </div>
                            </div>
                        </div>
                        <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                            <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                <thead className="bg-neutral-50 rounded-t-3xl">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.customer}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.address}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.phone}</th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.created_at}</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-neutral-200">
                                    {/* When there are no list available */}
                                    {customers.data.length === 0 && (
                                        <tr className="text-center">
                                            <td colSpan="6" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {lang.no_data}
                                            </td>
                                        </tr>
                                    )}

                                    {/* When there are no list available on searching */}
                                    {/* <tr className="text-center">
                                        <td colSpan="6" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                            {lang.no_result}
                                        </td>
                                    </tr> */}
                                    {customers.data.length > 0 && customers.data.map(row => (
                                        <tr key={row.id}>
                                            <td className="px-6 py-4 text-xs whitespace-nowrap text-neutral-500">
                                                <div class="flex items-center">
                                                    <img class="object-cover w-10 h-10 rounded-full" src={`https://ui-avatars.com/api/?name=${row.name}`} alt={`${row.name} Profile Photo`} />
                                                    <div class="ml-4">
                                                        <div className="flex flex-col">
                                                            <span className="font-medium text-neutral-900">{row.name}</span>
                                                            <span>{row.customer_code}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {row.addresses.length ? (
                                                    <div className="inline-flex items-center space-x-2">
                                                        <span>{row.addresses[0].name}</span>
                                                        {row.addresses.length > 1 && (
                                                            <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                                                                {`${row.addresses.length - 1}+`}
                                                            </span>
                                                        )}
                                                    </div>
                                                ) : "-"}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {row.phone ?? "-"}
                                            </td>
                                            <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                {moment(row.created_at).format('MMMM D, YYYY')}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination links={customers?.links} from={customers?.from} to={customers?.to} total={customers?.total} />
                    </div>
                </Transition>
            </div>
        </AppLayout>
    )
}

export default Customer