import { useState, useEffect } from "react"

import ErrorMessage from "components/forms/error-message"
import _ from "lodash"
import { lang } from "config"
import Label from "components/forms/label"
import Input from "components/forms/input"
import useFeatures from "repositories/feature"
import Checkbox from "components/forms/checkbox"

const Form = ({ data, errors, onSubmit }) => {
    const { data: featureSelection, isLoading: isLoadingFeature } = useFeatures({
        paginate: false
    })

    const [id, setId] = useState()
    const [feature, setFeature] = useState()
    const [accountNumber, setAccountNumber] = useState()

    const updateFeature = (data) => {
        if (feature?.find((row) => row.id === data.id)) {
            const value = feature.filter((row) => row.id !== data.id)

            setFeature(value)
        } else {
            setFeature([...feature, data])
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        onSubmit({
            terminal_id: id,
            features: feature,
            account_number: accountNumber
        })
    }

    useEffect(() => {
        setId(data?.id ?? null)
        setFeature(data?.features ?? [])
    }, [data])

    return (
        <div className="mt-8 space-y-8">
            <div className="space-y-4">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div>
                        <Label htmlFor="id" value={lang.terminal_id} />
                        <Input error={errors.terminal_id} onChange={(e) => setId(e.target.value)} value={id} id="id" />
                        <ErrorMessage error={errors.terminal_id} />
                    </div>
                    <div>
                        <Label htmlFor="account_number" value={lang.account_number} />
                        <Input error={errors.account_number} onChange={(e) => setAccountNumber(e.target.value)} value={accountNumber} id="account_number" />
                        <ErrorMessage error={errors.account_number} />
                    </div>
                </div>
                <div className="flex flex-col space-y-4">
                    <Label htmlFor="feature" value={lang.feature} />
                    <div className="space-y-2">
                        {featureSelection?.map((row) => (
                            <div className="flex items-center">
                                <div className="flex items-center h-5">
                                    <Checkbox error={errors.features} id={row.id} onChange={() => updateFeature(row)} value={row} checked={feature?.find((feature) => feature.id === row.id)} />
                                </div>
                                <div className="ml-3 text-sm">
                                    <label htmlFor={`${row.id}`} className={`font-medium text-gray-700 capitalize`}>
                                        {row.name}
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                    <ErrorMessage error={errors.features} />
                </div>
            </div>
            <div className="text-xs">
                <button type="button" onClick={handleSubmit} className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </div>
    )
}

export default Form