import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { motion } from "framer-motion"

import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import moment from "moment"
import ErrorMessage from "components/forms/error-message"
import axios from "axios"
import SelectInvoice from "./select-invoice"
import { UsersIcon, XIcon } from "components/icons"
import { useAuth } from "contexts/auth-context"
import useCustomers from "repositories/customer"
import toast from "react-hot-toast"
import InputAmount from "components/forms/input-amount"
import SupplierIcon from "components/icons/supplier"
import useSuppliers from "repositories/supplier"
import { useSWRConfig } from "swr"
import nProgress from "nprogress"
import usePaymentTypes from "repositories/payment-type"
import useAccounts from "repositories/account"
import useAccountNumbers from "repositories/account-number"
import { lang } from "config"
import useCurrencies from "repositories/currency"
import SelectDescription from "components/forms/select-description"
import useMerchants from "repositories/merchant"
import { usePage } from "contexts/page-context"

const Pay = ({ title }) => {
    const { user, isSuper, currentBranch } = useAuth()
    const { configuration } = usePage()

    const { cache } = useSWRConfig()

    const location = useLocation()
    const navigate = useNavigate()

    const date = moment().format('YYYY-MM-DD HH:mm:ss')

    const [prevRouteState, setPrevRouteState] = useState({})

    const [transition, setTransition] = useState(location.state?.transition)

    const [transactionType, setTransactionType] = useState(undefined)
    const [invoice, setInvoice] = useState([])
    const [branch, setBranch] = useState(undefined)
    const [customer, setCustomer] = useState(undefined)
    const [supplier, setSupplier] = useState(undefined)
    const [type, setType] = useState(undefined)
    const [currency, setCurrency] = useState("IDR")
    const [rate, setRate] = useState(1)

    const [method, setMethod] = useState(null)
    const [account, setAccount] = useState(null)
    const [merchant, setMerchant] = useState(null)
    const [number, setNumber] = useState(null)
    const [amount, setAmount] = useState(0)
    const [dueDate, setDueDate] = useState(moment().add(24, 'months').format("YYYY-MM-DD HH:mm:ss"))
    const [note, setNote] = useState(undefined)

    const [transactionDetail, setTransactionDetail] = useState(undefined)
    const [transactionPaymentAccount, setTransactionPaymentAccount] = useState(undefined)

    const [advancePayment, setAdvancePayment] = useState(null)
    const [grandTotal, setGrandTotal] = useState(null)

    const [errors, setErrors] = useState({})

    // Selections
    const { data: supplierSelection, isLoadingSupplier } = useSuppliers({
        paginate: false
    })
    const { data: customerSelection, isLoadingCustomer } = useCustomers({
        paginate: false
    })
    const { data: methodSelection, isLoadingMethod } = usePaymentTypes({
        paginate: false,
        show: 'cashier'
    })
    const { data: accountSelection, isLoadingAccount, mutate: mutateAccountSelection } = useAccounts({
        paginate: false,
        method
    })
    const { data: merchantSelection, isLoadingMerchant } = useMerchants({
        paginate: false
    })
    const { data: accountNumberSelection, isLoadingAccountNumber, mutate: mutateAccountNumberSelection } = useAccountNumbers({
        paginate: false,
        account_id: account?.id,
        branch_id: branch
    })
    const { data: currencySelection, isLoadingCurrency } = useCurrencies({})

    useEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }
        getPrevRouteState()
    }, [])

    useEffect(() => {
        setAccount(null)
        setNumber(null)
    }, [method])

    useEffect(() => {
        setNumber(null)
    }, [account])

    useEffect(() => {
        var transactionDetail = []
        var total = 0
        var advance = 0

        invoice.forEach(row => {
            if (row.transaction_type === 0) {
                total += parseFloat(row.grand_total)
            } else {
                total -= parseFloat(row.grand_total)
            }

            advance += parseFloat(row.advance_payment)
            transactionDetail.push({ invoice_number: row.invoice_number, amount: row.grand_total })
        })

        setType(invoice[0]?.type.code)

        if (invoice[0]?.type.code === 'po' || invoice[0]?.type.code === 'rt-po' || invoice[0]?.type.code === 'rt-adv') {
            setSupplier(invoice[0]?.payor_or_payee_code)
        } else {
            setCustomer(invoice[0]?.payor_or_payee_code)
        }

        if (total < 0) {
            setTransactionType(1)
        } else {
            setTransactionType(0)
        }

        setBranch(invoice[0]?.branch_id)
        setTransactionDetail(transactionDetail)
        setAdvancePayment(advance)
        setGrandTotal(Math.abs(total))
        if (invoice.length !== 0) {
            setCurrency(invoice[0]?.currency.toUpperCase())
            setRate(invoice[0]?.rate)
        }

        if (configuration('autofill_paid_input')) {
            var paid = Math.abs(total) - (advance > 0 ? advance : 0)

            setAmount(paid)
        }
    }, [invoice])

    useEffect(() => {
        setRate(currencySelection?.find((row) => row.code === currency)?.exchange_rate ?? 1)
    }, [currency])

    useEffect(() => {
        var transactionPaymentAccount = {
            payment_type: method,
            coa_number: account?.coa_number,
            merchant_id: merchant,
            account_number: method !== 'cash' ? number : undefined,
            giro_due_date: method === 'giro' ? dueDate : undefined,
            amount: amount ?? 0,
            transaction_type: transactionType
        }

        if (amount === undefined) {
            setAmount(0)
        }

        setTransactionPaymentAccount(transactionPaymentAccount)
    }, [method, account, number, dueDate, amount])

    const updateAccount = (value) => {
        const data = accountSelection?.find((row) => row.id === value)

        setAccount(data)
    }

    const handleSelect = (value) => {
        setInvoice(value)
    }

    const deselectCurrent = (value) => {
        setInvoice(invoice.filter((row) => row.invoice_number !== value.invoice_number))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        nProgress.start()

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/pay`, {
            branch_id: branch,
            payor_or_payee_code: (type === 'po' || type === 'rt-po' || type === 'rt-adv') ? supplier : customer,
            type,
            currency,
            rate,
            transaction_detail: transactionDetail,
            transaction_payment_account: transactionPaymentAccount,
            advance_payment: advancePayment > 0 ? advancePayment : undefined,
            grand_total: grandTotal,
            note,
            transaction_type: transactionType,
            transaction_date: date,
            created_by: user?.name,
            no_approve: isSuper() ? 1 : 0
        }).then((response) => {
            cache.clear()
            navigate({ pathname: "/transaction" })
            toast.success(response.data.message)
        }).catch((error) => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            nProgress.done()
            toast.error(error.response.data.message)
            console.log(error.response)
        })
    }

    return (
        <AppLayout title={title}>
            <div className="p-4 space-y-16 lg:p-8">
                <div>
                    {prevRouteState?.back && (
                        <button>
                            <Link to={location.state?.from} state={{ back: null, from: location.pathname, transition: 'slide' }} className="transition hover:opacity-50">
                                <motion.h3 layout transition={{ duration: .5, type: "tween" }} layoutId={prevRouteState?.back} className="text-sm">{prevRouteState?.back}</motion.h3>
                            </Link>
                        </button>
                    )}
                    <Transition type="slide" reversed>
                        <h1 className="text-3xl font-medium">
                            {title}
                        </h1>
                    </Transition>
                </div>
                <Transition type="slide" reversed>
                    <div className="space-y-16">
                        <div className="space-y-4">
                            <div className={`${errors.transaction_type ? 'border-red-200' : 'border-neutral-200'} overflow-x-auto border rounded-xl`}>
                                <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                                    <thead className="bg-neutral-50 rounded-t-3xl">
                                        <tr>
                                            <th scope="col" className="relative px-6 py-3"><span className="sr-only">{lang.action}</span></th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.invoice}</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.grand_total}</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.branch}</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.customer_or_supplier}</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.type}</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.transaction_type}</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.invoice_date}</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.due_date}</th>
                                            <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">{lang.customer_or_supplier_code}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-neutral-200">
                                        {/* When there are no list available */}
                                        {invoice.length === 0 && (
                                            <tr className="text-center">
                                                <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {lang.no_invoice_selected}
                                                </td>
                                            </tr>
                                        )}

                                        {/* When there are no list available on searching */}
                                        {/* <tr className="text-center">
                                        <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                            {lang.no_result}
                                        </td>
                                    </tr> */}
                                        {invoice.length > 0 && invoice.map(row => (
                                            <tr key={row.invoice_number}>
                                                <td className="px-6 py-4 text-xs font-medium text-right whitespace-nowrap">
                                                    <div className="inline-flex items-center space-x-2">
                                                        <Link onClick={() => setTransition('slide')} to={`/invoice/${row.invoice_number}`} state={{ back: "Pay", from: location.pathname, transition: 'slide' }} className="inline-flex items-center p-1 text-white transition rounded-full bg-neutral-800 active:hover:scale-90">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <circle cx={12} cy={12} r={2}></circle>
                                                                <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                                            </svg>
                                                        </Link>

                                                        <button onClick={() => deselectCurrent(row)} class="inline-flex items-center p-1 text-white transition bg-red-800 rounded-full active:hover:scale-90">
                                                            <XIcon className={`w-6 h-6`} />
                                                        </button>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                    {row.invoice_number}
                                                </td>
                                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                    {row.currency && Intl.NumberFormat('id-Id', { style: 'currency', currency: row.currency }).format(row.grand_total)}
                                                </td>
                                                <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                    {row.branch?.name}
                                                </td>
                                                <td class="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                                    <div className="flex items-center space-x-2">
                                                        <span>
                                                            {(row.type?.code === 'po' || row.type?.code === 'rt-po' || row.type?.code === 'rt-adv') ? (
                                                                <SupplierIcon className="w-4 h-4" stroke={1.5} />
                                                            ) : (
                                                                <UsersIcon className="w-4 h-4" stroke={1.5} />
                                                            )}
                                                        </span>
                                                        <span>
                                                            {(row.type?.code === 'po' || row.type?.code === 'rt-po' || row.type?.code === 'rt-adv') ? row.supplier?.name : row.customer?.name}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {row.type?.name}
                                                </td>
                                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {row.transaction_type === 0 ? lang.income : lang.expense}
                                                </td>
                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {moment(row.invoice_date).format('MMMM D, YYYY')}
                                                </td>
                                                <td className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {moment(row.due_date).format('MMMM D, YYYY')}
                                                </td>
                                                <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                                    {row.payor_or_payee_code}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <SelectInvoice error={errors.transaction_type} selected={invoice} onSelect={handleSelect} />
                            </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="mt-10 lg:mt-0">
                                <div className="lg:grid lg:grid-cols-3 lg:gap-6">
                                    <div className="lg:col-span-1">
                                        <div>
                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                                        </div>
                                    </div>
                                    <div className="mt-5 lg:col-span-2 lg:mt-0">
                                        <div className="overflow-hidden border rounded-xl">
                                            <div className="px-4 py-5 bg-white lg:p-6">
                                                <div className="grid grid-cols-6 gap-4">
                                                    {(!type || (type === 'po' || type === 'rt-po' || type === 'rt-adv')) && (
                                                        <div className="col-span-6 lg:col-span-3">
                                                            <label for="supplier" className="block text-xs text-neutral-700">{lang.supplier}</label>
                                                            <select disabled={invoice.length !== 0} value={supplier} name="supplier" id="supplier" className={`${errors.payor_or_payee_code ? 'border-red-200' : 'border-neutral-200'} ${invoice.length !== 0 ? 'bg-neutral-100 opacity-70' : ''} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                                                <option value={null} selected disabled>-- {lang.choose} {lang.supplier} --</option>
                                                                {supplierSelection?.length !== 0 && supplierSelection?.map(row => (
                                                                    <option value={row.supplier_code}>{row.name}</option>
                                                                ))}
                                                            </select>
                                                            <ErrorMessage error={errors.payor_or_payee_code} />
                                                        </div>
                                                    )}

                                                    {(!type || (type !== 'po' && type !== 'rt-po' && type !== 'rt-adv')) && (
                                                        <div className="col-span-6 lg:col-span-3">
                                                            <label for="customer" className="block text-xs text-neutral-700">{lang.customer}</label>
                                                            <select disabled={invoice.length !== 0} value={customer} name="customer" id="customer" className={`${errors.payor_or_payee_code ? 'border-red-200' : 'border-neutral-200'} ${invoice.length !== 0 ? 'bg-neutral-100 opacity-70' : ''} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                                                <option value={null} selected disabled>-- {lang.choose} {lang.customer} --</option>
                                                                {customerSelection?.length !== 0 && customerSelection?.map(row => (
                                                                    <option value={row.customer_code}>{row.name}</option>
                                                                ))}
                                                            </select>
                                                            <ErrorMessage error={errors.payor_or_payee_code} />
                                                        </div>
                                                    )}

                                                    {type && (
                                                        <div className="col-span-3"></div>
                                                    )}

                                                    <div className="col-span-6 lg:col-span-3">
                                                        <label for="currency" className="block text-xs text-neutral-700">{lang.currency}</label>
                                                        <select disabled={true} onChange={(e) => setCurrency(e.target.value)} value={currency} name="currency" id="currency" className={`${errors.currency ? 'border-red-200' : 'border-neutral-200'} ${true ? 'bg-neutral-100 opacity-70' : ''} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                                            <option value={null} selected disabled>-- {lang.choose} {lang.currency} --</option>
                                                            {currencySelection?.length !== 0 && currencySelection?.map(row => (
                                                                <option value={row.code}>{row.code}</option>
                                                            ))}
                                                        </select>
                                                        <ErrorMessage error={errors.currency} />
                                                    </div>

                                                    <div className="col-span-6 lg:col-span-3">
                                                        <label for="rate" className="block text-xs text-neutral-700">{lang.exchange_rate}</label>
                                                        <InputAmount disabled value={parseFloat(rate)} error={errors.rate} id="rate" />
                                                        <ErrorMessage error={errors.rate} />
                                                    </div>

                                                    <div className="col-span-6">
                                                        <label for="note" className="block text-xs text-neutral-700">{lang.note}</label>
                                                        <textarea onChange={e => setNote(e.target.value)} value={note} name="note" id="note" rows="5" className={`${errors.note ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}></textarea>
                                                        <ErrorMessage error={errors.note} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="hidden lg:block" aria-hidden="true">
                                <div className="py-5">
                                    <div className="border-t border-gray-200" />
                                </div>
                            </div>

                            <div className="mt-10 lg:mt-0">
                                <div className="lg:grid lg:grid-cols-3 lg:gap-6">
                                    <div className="lg:col-span-1">
                                        <div>
                                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.payments}</h3>
                                            <p className="mt-1 text-sm text-gray-600">{lang.more_detailed_form_about_payment_method_and_more}</p>
                                        </div>
                                    </div>
                                    <div className="mt-5 lg:col-span-2 lg:mt-0">
                                        <div className="overflow-hidden border rounded-xl">
                                            <div className="px-4 py-5 space-y-6 bg-white lg:p-6">
                                                <fieldset className="space-y-4">
                                                    <legend className="sr-only" hidden>{lang.payment}</legend>
                                                    <div className="text-base font-medium text-gray-900" aria-hidden="true">
                                                        {lang.payment}
                                                    </div>
                                                    <div className="grid grid-cols-6 gap-4">
                                                        <div className="col-span-6 lg:col-span-3">
                                                            <label for="method" className="block text-xs text-neutral-700">{lang.payment_method}</label>
                                                            <select onChange={(e) => setMethod(e.target.value)} value={method} name="method" id="method" className={`${errors['transaction_payment_account.payment_type'] ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                                                {isLoadingMethod && (
                                                                    <option value={null} disabled selected>{lang.loading}</option>
                                                                )}
                                                                {!isLoadingMethod && (
                                                                    <option value={null} disabled selected>-- {lang.choose} {lang.payment_method} --</option>
                                                                )}
                                                                {!isLoadingMethod && methodSelection?.map((row) => (
                                                                    <option value={row.code}>{row.name}</option>
                                                                ))}
                                                            </select>
                                                            <ErrorMessage error={errors['transaction_payment_account.payment_type']} />
                                                        </div>
                                                        {method === 'edc' && (
                                                            <div className="col-span-6 lg:col-span-3">
                                                                <label for="merchant" className="block text-xs text-neutral-700">{lang.merchant}</label>
                                                                <SelectDescription isLoading={isLoadingMerchant} onChange={(value) => setMerchant(value)} title="name" description="merchant_id" value={merchant} keyValue="merchant_id" selection={merchantSelection} placeholder={`-- ${lang.choose} ${lang.merchant} --`} error={errors['transaction_payment_account.coa_number']} />
                                                                <ErrorMessage error={errors['transaction_payment_account.merchant']} />
                                                            </div>
                                                        )}
                                                        <div className="col-span-6 lg:col-span-3">
                                                            <label for="account" className="block text-xs text-neutral-700">{lang.account}</label>
                                                            {/* <select onChange={(e) => setAccount(e.target.value)} value={account} name="account" id="account" className={`${errors['transaction_payment_account.coa_number'] ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                                                {isLoadingAccount && (
                                                                    <option value={null} disabled selected={account === null}>{lang.loading}</option>
                                                                )}
                                                                {!isLoadingAccount && (
                                                                    <option value={null} disabled selected={account === null}>-- {lang.choose} {lang.account} --</option>
                                                                )}
                                                                {!isLoadingAccount && accountSelection?.map(row => (
                                                                    <option value={row.coa_number}>{row.name}</option>
                                                                ))}
                                                            </select> */}
                                                            <SelectDescription isLoading={isLoadingAccount} onChange={(value) => updateAccount(value)} title="name" description="coa_number" value={account?.id} keyValue="id" selection={accountSelection} placeholder={`-- ${lang.choose} ${lang.account} --`} error={errors['transaction_payment_account.coa_number']} />
                                                            <ErrorMessage error={errors['transaction_payment_account.coa_number']} />
                                                        </div>
                                                        {(method !== 'cash' && method !== 'marketplace') && (
                                                            <div className="col-span-6 lg:col-span-3">
                                                                <label for="number" className="block text-xs text-neutral-700">{lang.account_number}</label>
                                                                <select onChange={(e) => setNumber(e.target.value)} value={number} name="number" id="number" className={`${errors['transaction_payment_account.account_number'] ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                                                    {isLoadingAccountNumber && (
                                                                        <option value={null} disabled selected={number === null}>Loading</option>
                                                                    )}
                                                                    {!isLoadingAccountNumber && (
                                                                        <option value={null} disabled selected={number === null}>-- {lang.choose} {lang.account_number} --</option>
                                                                    )}
                                                                    {!isLoadingAccountNumber && accountNumberSelection?.map(row => (
                                                                        <option value={row.number}>{row.number}</option>
                                                                    ))}
                                                                </select>
                                                                <ErrorMessage error={errors['transaction_payment_account.account_number']} />
                                                            </div>
                                                        )}
                                                        <div className="col-span-6 lg:col-span-3">
                                                            <label for="amount" className="block text-xs text-neutral-700">{lang.amount}</label>
                                                            <InputAmount onChange={value => setAmount(value)} value={parseFloat(amount)} error={errors['transaction_payment_account.amount']} id="amount" />
                                                            <ErrorMessage error={errors['transaction_payment_account.amount']} />
                                                        </div>
                                                        {method === 'giro' && (
                                                            <div className="col-span-6 lg:col-span-3">
                                                                <label for="due_date" className="block text-xs text-neutral-700">{lang.giro_due_date}</label>
                                                                <input onChange={e => setDueDate(e.target.value)} value={dueDate} id="due_date" autoComplete="off" type="date" className={`${errors['transaction_payment_account.giro_due_date'] ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`} />
                                                                <ErrorMessage error={errors['transaction_payment_account.giro_due_date']} />
                                                            </div>
                                                        )}
                                                    </div>
                                                </fieldset>
                                                <fieldset className="space-y-4">
                                                    <legend className="text-base font-medium text-gray-900 contents">{lang.amounts}</legend>
                                                    <div>
                                                        <p className="text-sm text-gray-800">{lang.grand_total}</p>
                                                        <p className="text-xs text-gray-500">{currency && Intl.NumberFormat('id-Id', { style: 'currency', currency }).format(grandTotal)}</p>
                                                    </div>
                                                    {advancePayment > 0 && (
                                                        <div>
                                                            <p className="text-sm text-gray-800">{lang.advance_payment}</p>
                                                            <p className="text-xs text-gray-500">{currency && Intl.NumberFormat('id-Id', { style: 'currency', currency }).format(advancePayment)}</p>
                                                        </div>
                                                    )}
                                                    <div>
                                                        <p className="text-sm text-gray-800">{lang.paid}</p>
                                                        <p className="text-xs text-gray-500">{currency && Intl.NumberFormat('id-Id', { style: 'currency', currency }).format(amount)}</p>
                                                    </div>
                                                    {(grandTotal - (advancePayment > 0 ? advancePayment : 0)) > amount && (
                                                        <div>
                                                            <p className="text-sm text-gray-800">{lang.remaining}</p>
                                                            <p className="text-xs text-gray-500">{currency && Intl.NumberFormat('id-Id', { style: 'currency', currency }).format((grandTotal - (advancePayment > 0 ? advancePayment : 0)) - amount)}</p>
                                                        </div>
                                                    )}
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-8 text-xs text-right">
                                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                                    <span>{lang.pay}</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </Transition>
            </div>
        </AppLayout>
    )
}

export default Pay