import { useState, useEffect } from "react"

import useBranches from "repositories/branch"
import nProgress from "nprogress"
import ErrorMessage from "components/forms/error-message"
import lodash from "lodash"
import deepdash from "deepdash"
import InputAmount from "components/forms/input-amount"
import { useAuth } from "contexts/auth-context"
import { lang } from "config"
import useCurrencies from "repositories/currency"
import ChartOfAccountCombobox from "components/forms/chart-of-account-combobox"
import DestinationList from "./destination-list"
import useChartOfAccounts from "repositories/source"
import Checkbox from "components/forms/checkbox"
import { usePage } from "contexts/page-context"
import toast from "react-hot-toast"

const Form = ({ data, errors, onSubmit }) => {
    const _ = deepdash(lodash)

    const { configuration } = usePage()
    const { currentBranch, isSuper } = useAuth()

    const { data: branchSelection, isLoading: isLoadingBranches } = useBranches({
        paginate: false
    })
    const { data: currencySelection, isLoading: isLoadingCurrency } = useCurrencies({})
    const { data: sourceSelection, isLoading: isLoadingSource } = useChartOfAccounts({
        type: 'source'
    }, `${process.env.REACT_APP_ACCOUNTING_API_URL}/chart-of-account`)

    useEffect(() => {
        if (isLoadingBranches) {
            nProgress.start()
        } else {
            nProgress.done()
        }
    }, [isLoadingBranches])

    const transactionNumber = data?.transaction_number ?? undefined

    const [branch, setBranch] = useState()
    const [transactionType, setTransactionType] = useState(data?.transaction_type ?? null)
    const [currency, setCurrency] = useState(data?.currency.toUpperCase() ?? "IDR")
    const [rate, setRate] = useState(data?.rate ?? 1)

    const [grandTotal, setGrandTotal] = useState(parseFloat(data?.grand_total) ?? null)

    const [source, setSource] = useState(data?.source ?? null)

    const [detail, setDetail] = useState(data?.details ?? [])

    const [note, setNote] = useState(data?.note ?? null)

    const [isPayLater, setIsPaylater] = useState(data?.payment_flag ?? false)

    useEffect(() => {
        if (currency && !data) {
            setRate(currencySelection?.find((row) => row.code === currency).exchange_rate ?? 1)
        }
    }, [currency])

    useEffect(() => {
        var grandTotal = 0

        detail.map((row) => {
            grandTotal += parseFloat(row.subtotal)

            if (row.references.length && detail.length === 1) {
                toast.success("OK")

                var amount = 0

                row.references.forEach((reference) => {
                    if (reference.transaction_type === 0) {
                        amount += parseFloat(reference.grand_total)
                    } else {
                        amount -= parseFloat(reference.grand_total)
                    }
                })

                if (amount >= 0) {
                    setTransactionType(0)
                } else {
                    setTransactionType(1)
                }
            } else {
                setTransactionType(null)
            }
        })

        if (configuration('karyawan_accounts').find((row) => row == detail[0]?.destination.number)) {
            setIsPaylater(1)
        } else {
            setIsPaylater(0)
        }

        setGrandTotal(grandTotal)
    }, [detail])

    const toggleIsPaylater = () => {
        setIsPaylater(!isPayLater)
    }

    useEffect(() => {
        if (isPayLater) {
            setSource(null)
        }
    }, [isPayLater])

    useEffect(() => {
        if (!isLoadingBranches) {
            setBranch(data?.branch_id ?? (isSuper() ? (branchSelection.find((row) => row.id === 1)?.id ?? branchSelection[0]?.id) : currentBranch?.id))
        }
    }, [isLoadingBranches])

    const handleSubmit = (e) => {
        e.preventDefault()

        var details = []

        detail.forEach((row) => {
            details.push({
                ...row,
                destination: row.destination.number
            })
        })

        onSubmit({
            transaction_number: transactionNumber,
            branch_id: branch,
            transaction_type: transactionType,
            currency,
            rate,
            grand_total: grandTotal,
            source,
            note,
            details
        })
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="overflow-hidden border sm:rounded-xl">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="col-span-2">
                                        <label for="destination" className="block text-xs text-neutral-700">{lang.destination}</label>
                                        <DestinationList enableAction={!data} data={detail} onChange={(value) => setDetail(value)} largeButton />
                                        <ErrorMessage error={errors.details} />
                                    </div>

                                    <div className="col-span-2">
                                        <label for="source" className="block text-xs text-neutral-700">{lang.source}</label>
                                        <ChartOfAccountCombobox error={errors.source} disabled={data} isLoading={isLoadingSource} selection={sourceSelection ?? []} onChange={(value) => setSource(value.number)} value={source} />
                                        <ErrorMessage error={errors.source} />
                                    </div>

                                    <div>
                                        <label for="branch" className="block text-xs text-neutral-700">{lang.branch}</label>
                                        <select disabled={data} onChange={(e) => setBranch(e.target.value)} value={branch} name="branch" id="branch" className={`${errors.branch_id ? 'border-red-200' : 'border-neutral-200'} ${data ? 'bg-neutral-100 opacity-70' : ''} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                            {isLoadingBranches && (
                                                <option value={null} disabled selected>{lang.loading}</option>
                                            )}
                                            {!isLoadingBranches && (
                                                <option value={null} disabled selected={branch === null}>{`-- ${lang.choose} ${lang.branch} --`}</option>
                                            )}
                                            {!isLoadingBranches && branchSelection?.map(row => (
                                                <option value={row.id}>{row.name}</option>
                                            ))}
                                        </select>
                                        <ErrorMessage error={errors.branch_id} />
                                    </div>

                                    <div>
                                        <label for="transaction_type" className="block text-xs text-neutral-700">{lang.transaction_type}</label>
                                        <select disabled={data} onChange={(e) => setTransactionType(e.target.value)} value={transactionType} name="transaction_type" id="transaction_type" className={`${errors.transaction_type ? 'border-red-200' : 'border-neutral-200'} ${data ? 'bg-neutral-100 opacity-70' : ''} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                            <option value={null} disabled selected={transactionType === null}>-- {lang.choose} {lang.transaction_type} --</option>
                                            <option value={0}>{lang.income}</option>
                                            <option value={1}>{lang.expense}</option>
                                            <option value={2}>{lang.internal_transfer}</option>
                                        </select>
                                        <ErrorMessage error={errors.transaction_type} />
                                    </div>

                                    <div>
                                        <label for="currency" className="block text-xs text-neutral-700">{lang.currency}</label>
                                        <select disabled={true} onChange={(e) => setCurrency(e.target.value)} value={currency} name="currency" id="currency" className={`${errors.currency ? 'border-red-200' : 'border-neutral-200'} ${true ? 'bg-neutral-100 opacity-70' : ''} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                            {isLoadingCurrency && (
                                                <option value={null} disabled selected>{lang.loading}</option>
                                            )}
                                            {!isLoadingCurrency && (
                                                <option value={null} disabled selected>{`-- ${lang.choose} ${lang.currency} --`}</option>
                                            )}
                                            {!isLoadingCurrency && currencySelection?.map(row => (
                                                <option value={row.code}>{row.code}</option>
                                            ))}
                                        </select>
                                        <ErrorMessage error={errors.currency} />
                                    </div>

                                    <div>
                                        <label for="rate" className="block text-xs text-neutral-700">{lang.exchange_rate}</label>
                                        <InputAmount disabled onChange={value => setRate(value)} value={parseFloat(rate)} error={errors.rate} id="rate" />
                                        <ErrorMessage error={errors.rate} />
                                    </div>

                                    <div className="col-span-2">
                                        <label for="note" className="block text-xs text-neutral-700">{lang.note}</label>
                                        <textarea onChange={e => setNote(e.target.value)} value={note} name="note" id="note" rows="5" className="block w-full px-4 py-2 mt-1 text-sm transition border border-neutral-200 focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200"></textarea>
                                        <ErrorMessage error={errors.note} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200" />
                </div>
            </div>

            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.tax_and_amount}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.more_detailed_form_about_tax_and_more}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="overflow-hidden border sm:rounded-xl">
                            <div className="px-4 py-5 space-y-4 bg-white sm:p-6">
                                <div>
                                    <p className={`${errors.grand_total ? 'text-red-500' : 'text-gray-800'} text-sm`}>{lang.grand_total}</p>
                                    <p className={`${errors.grand_total ? 'text-red-400' : 'text-gray-500'} text-xs`}>{currency && Intl.NumberFormat('id-Id', { style: 'currency', currency }).format(!isNaN(grandTotal) ? grandTotal : 0)}</p>
                                    <ErrorMessage error={errors.grand_total} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{`${_.isEmpty(data) ? lang.create : lang.update}`}</span>
                </button>
            </div>
        </form>
    )
}

export default Form