import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from 'components/icons'
import { AnimatePresence, motion } from 'framer-motion'
import { Fragment, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'

const Tooltip = ({ isOpen, children, reference, position, onClose, showOverlay = true }) => {
    let [referenceElement, setReferenceElement] = useState(reference)
    let [popperElement, setPopperElement] = useState()
    let { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: position,
        strategy: 'fixed'
    })

    useEffect(() => {
        setReferenceElement(reference)
    }, [reference])

    return (
        <Popover>
            <>
                {/* <Transition as={Fragment} enter="transition ease-out duration-200" enterFrom="opacity-0 translate-y-1" enterTo="opacity-100 translate-y-0" leave="transition ease-in duration-150" leaveFrom="opacity-100 translate-y-0" leaveTo="opacity-0 translate-y-1"> */}
                <AnimatePresence mode="wait">
                    {isOpen && (
                        <>
                            {showOverlay && (
                                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ type: "spring", duration: .5 }} onClick={onClose} static className="absolute inset-0 bg-white bg-opacity-80" />
                            )}
                            <div onClick={onClose} static ref={setPopperElement} style={styles.popper} {...attributes.popper}>
                                <motion.div transition={{ type: "spring", duration: .5 }} initial={{ opacity: 0, x: '.3rem' }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: '.3rem' }}>
                                    {children}
                                </motion.div>
                            </div>
                        </>
                    )}
                </AnimatePresence>
                {/* </Transition> */}
            </>
        </Popover >
    )
}

export default Tooltip