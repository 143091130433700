import { motion } from "framer-motion"

const defaultVariant = {
    initial: { opacity: 0 },
    animate: {
        opacity: 1,
        transition: {
            duration: .5,
            type: 'tween'
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: .5,
            type: 'tween'
        }
    },
}

const slideVariant = {
    initial: { opacity: 0, x: '-100%' },
    animate: {
        opacity: 1,
        x: 0,
        transition: {
            type: 'spring',
            damping: 20
        }
    },
    exit: {
        opacity: 0,
        x: '-100%',
        transition: {
            type: 'spring',
            damping: 20
        }
    },
}

const reversedSlideVariant = {
    initial: { opacity: 0, x: '100%' },
    animate: {
        opacity: 1,
        x: 0,
        transition: {
            type: 'spring',
            damping: 20
        }
    },
    exit: {
        opacity: 0,
        x: '100%',
        transition: {
            type: 'spring',
            damping: 20
        }
    },
}

const Transition = ({ children, type, reversed }) => {
    const variant = type === 'slide' ? (reversed ? reversedSlideVariant : slideVariant) : defaultVariant

    return (
        <motion.div
            variants={variant}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            {children}
        </motion.div>
    )
}

export default Transition