import ErrorMessage from "components/forms/error-message"
import Input from "components/forms/input"
import InputAmount from "components/forms/input-amount"
import InputDate from "components/forms/input-date"
import Label from "components/forms/label"
import { useAuth } from "contexts/auth-context"
import moment from "moment"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import useBills from "repositories/bill"
import useBranches from "repositories/branch"
import useCustomers from "repositories/customer"
import usePurchaseOrders from "repositories/purchase-order"
import useSuppliers from "repositories/supplier"
import { lang } from "config"
import useCurrencies from "repositories/currency"
import axios from "axios"
import nProgress from "nprogress"
import _ from "lodash"
import { toast } from "react-hot-toast"

const Form = ({ data, errors, onSubmit }) => {
    const { user, currentBranch, isSuper } = useAuth()

    const location = useLocation()
    const navigate = useNavigate()

    const [prevRouteState, setPrevRouteState] = useState({})

    const [branch, setBranch] = useState(data?.branch_id ?? currentBranch?.id)
    const [transactionType, setTransactionType] = useState(data?.transaction_type ?? 1)
    const [taxInvoiceNumber, setTaxInvoiceNumber] = useState(data?.tax_invoice_number)
    const [date, setDate] = useState(data?.transaction_date ?? moment().format('YYYY-MM-DD'))
    const [customer, setCustomer] = useState(data?.payor_or_payee_code)
    const [supplier, setSupplier] = useState(data?.payor_or_payee_code)
    const [currency, setCurrency] = useState(data?.currency.toUpperCase() ?? 'IDR')
    const [rate, setRate] = useState(data?.rate ?? 1)
    const [note, setNote] = useState(data?.note)

    const [amount, setAmount] = useState(data?.amount)
    const [ppn, setPpn] = useState(data?.ppn ?? 0)
    const [ppnPercentage, setPpnPercentage] = useState(data?.ppn_percentage ?? 0)

    const [reference, setReference] = useState(data?.reference_number[0])
    const [referenceNumber, setReferenceNumber] = useState([])

    const [subtotal, setSubtotal] = useState(data?.subtotal ?? null)
    const [max, setMax] = useState(null)

    const [isCalculating, setIsCalculating] = useState(false)

    const { data: branchSelection, isLoading: isLoadingBranch } = useBranches({
        paginate: false
    })
    const { data: customerSelection, isLoading: isLoadingCustomer } = useCustomers({
        paginate: false
    })
    const { data: supplierSelection, isLoading: isLoadingSupplier } = useSuppliers({
        paginate: false
    })
    const { data: POSelection, isLoading: isLoadingPO, mutate: mutatePO } = usePurchaseOrders({ supplier_code: supplier }, `${process.env.REACT_APP_MAIN_APP_API_URL}/purchase-order/data-outstanding`)
    const { data: billSelection, isLoading: isLoadingBill, mutate: mutateBill } = useBills({
        paginate: false,
        status: 'outstanding'
    })
    const { data: currencySelection, isLoading: isLoadingCurrency } = useCurrencies({})

    useEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }
        getPrevRouteState()
    }, [])

    useEffect(() => {
        if (isCalculating) {
            nProgress.start()
        } else {
            nProgress.done()
        }
    }, [isCalculating])

    useEffect(() => {
        if (!data && (!isLoadingBill && transactionType === 0) || (!isLoadingPO && transactionType === 1) && reference) {
            setIsCalculating(true)

            var referenceNumber = []
            var ppn = 0

            var selected = {}

            if (transactionType === 1) {
                selected = POSelection.find((row) => row.po_number === reference)
            } else {
                selected = billSelection.find((row) => row.bill_number === reference)
            }

            // reference.forEach((row) => {
            //     if (transactionType === 1) {
            //         referenceNumber.push(row.po_number)
            //     } else {
            //         referenceNumber.push(row.bill_number)
            //     }

            //     if (transactionType === 1) {
            //         max += row.total
            //     } else {
            //         max += row.grand_total
            //     }
            // })

            if (transactionType === 1) {
                referenceNumber.push(selected.po_number)
            } else {
                referenceNumber.push(selected.bill_number)
            }

            if (transactionType === 1) {
                ppn += parseFloat(selected.PPN)
            } else {
                ppn += parseFloat(selected.ppn)
            }

            setPpnPercentage(parseInt(selected.ppn_percentage))
            setPpn(ppn)
            setReferenceNumber(referenceNumber)

            if (amount >= 0) {
                setSubtotal(parseFloat(amount))
            } else if (!amount) {
                setSubtotal(0)
            }

            axios.get(`${process.env.REACT_APP_BACKEND_URL}/advance/by/po_number/${reference}`).then((response) => {
                const existingAdvancePayment = response.data.advance

                var max = 0
                var subtotal = 0

                existingAdvancePayment.map((row) => {
                    subtotal += parseFloat(row.subtotal)
                })

                if (transactionType === 1) {
                    max += (parseFloat(selected.total) - subtotal)
                } else {
                    max += (parseFloat(selected.grand_total - subtotal))
                }

                setMax(max)
                setIsCalculating(false)
            })
        }
    }, [reference, amount])

    useEffect(() => {
        setRate(currencySelection?.find((row) => row.code === currency)?.exchange_rate ?? 1)
    }, [currency])

    useEffect(() => {
        mutatePO()
        mutateBill()
    }, [customer, supplier])

    const handleSubmit = (e) => {
        e.preventDefault()

        onSubmit({
            branch_id: branch,
            payor_or_payee_code: transactionType === 1 ? supplier : customer,
            reference_number: referenceNumber,
            currency,
            rate,
            ppn,
            ppn_percentage: ppnPercentage,
            tax_invoice_number: taxInvoiceNumber,
            amount,
            subtotal,
            max,
            note,
            transaction_date: date,
            transaction_type: transactionType
        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.main_form}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.please_fill_the_provided_form_input}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="overflow-hidden border sm:rounded-xl">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="grid grid-cols-6 gap-4">
                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="branch" className="block text-xs text-neutral-700">{lang.branch}</label>
                                        <select disabled={data} onChange={(e) => setBranch(e.target.value)} value={branch} name="branch" id="branch" className={`${errors?.branch_id ? 'border-red-200' : 'border-neutral-200'} ${data ? 'bg-neutral-100 opacity-70' : ''} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                            {isLoadingBranch && (
                                                <option value={null} disabled selected>{lang.loading_data}</option>
                                            )}
                                            {!isLoadingBranch && (
                                                <option value={null} disabled selected={!branch}>-- {lang.choose} {lang.branch} --</option>
                                            )}
                                            {!isLoadingBranch && branchSelection.map(row => (
                                                <option value={row.id}>{row.name}</option>
                                            ))}
                                        </select>
                                        <ErrorMessage error={errors?.branch_id} />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="transaction_type" className="block text-xs text-neutral-700">{lang.transaction_type}</label>
                                        <select disabled onChange={(e) => setTransactionType(e.target.value)} value={transactionType} name="transaction_type" id="transaction_type" className={`${errors?.transaction_type ? 'border-red-200' : 'border-neutral-200'} bg-neutral-100 opacity-70 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                            <option value={null} selected disabled>-- Select {lang.transaction_type} --</option>
                                            <option value={0}>{lang.income}</option>
                                            <option value={1}>{lang.expense}</option>
                                        </select>
                                        <ErrorMessage error={errors?.transaction_type} />
                                    </div>

                                    {/* {(!data || (data && transactionType === 0)) && (
                                        <div className="col-span-6 sm:col-span-3">
                                            <label for="customer" className="block text-xs text-neutral-700">{lang.customer}</label>
                                            <select disabled={parseInt(transactionType) === 1 || data} onChange={(e) => setCustomer(e.target.value)} value={customer} name="customer" id="customer" className={`${errors?.payor_or_payee_code ? 'border-red-200' : 'border-neutral-200'} ${parseInt(transactionType) === 1 || data ? 'bg-neutral-100 opacity-70' : ''} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                                <option value={undefined} selected={customer === undefined || parseInt(transactionType) === 1} disabled>-- {lang.choose} {lang.customer} --</option>
                                                {!isLoadingCustomer && customerSelection.length !== 0 && customerSelection.map(row => (
                                                    <option value={row.customer_code}>{row.name}</option>
                                                ))}
                                            </select>
                                            <ErrorMessage error={errors?.payor_or_payee_code} />
                                        </div>
                                    )} */}

                                    {(!data || (data && transactionType === 1)) && (
                                        <div className="col-span-6 sm:col-span-3">
                                            <label for="supplier" className="block text-xs text-neutral-700">{lang.supplier}</label>
                                            <select disabled={parseInt(transactionType) === 0 || data} onChange={(e) => setSupplier(e.target.value)} value={supplier} name="supplier" id="supplier" className={`${errors?.payor_or_payee_code ? 'border-red-200' : 'border-neutral-200'} ${parseInt(transactionType) === 0 || data ? 'bg-neutral-100 opacity-70' : ''} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                                <option value={undefined} selected={supplier === undefined || parseInt(transactionType) === 0} disabled>-- {lang.choose} {lang.supplier} --</option>
                                                {!isLoadingSupplier && supplierSelection.length !== 0 && supplierSelection.map(row => (
                                                    <option value={row.supplier_code}>{row.name}</option>
                                                ))}
                                            </select>
                                            <ErrorMessage error={errors?.payor_or_payee_code} />
                                        </div>
                                    )}

                                    {/* Reference Number */}
                                    <div className="col-span-6 sm:col-span-3">
                                        <Label htmlFor="reference" value={lang.reference_number} />
                                        <select disabled={data} onChange={(e) => setReference(e.target.value)} value={reference} name="reference" id="reference" className={`${errors?.reference_number ? 'border-red-200' : 'border-neutral-200'} ${data ? 'bg-neutral-100 opacity-70' : ''} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                            {(supplier || customer) && (
                                                <>
                                                    {transactionType === 1 ? (
                                                        <>
                                                            {isLoadingPO && (
                                                                <option value={undefined} selected disabled>{lang.loading_data}</option>
                                                            )}
                                                            {!isLoadingPO && (
                                                                <option value={undefined} selected={!reference} disabled>-- {lang.choose} {lang.reference_number} --</option>
                                                            )}
                                                            {!isLoadingPO && POSelection?.length !== 0 && POSelection?.map(row => (
                                                                <option value={row.po_number}>{row.po_number}</option>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {isLoadingBill && (
                                                                <option value={undefined} selected disabled>{lang.loading_data}</option>
                                                            )}
                                                            {!isLoadingBill && (
                                                                <option value={undefined} selected={!reference} disabled>-- {lang.choose} {lang.reference_number} --</option>
                                                            )}
                                                            {!isLoadingBill && billSelection?.length !== 0 && billSelection?.map(row => (
                                                                <option value={row.bill_number}>{row.bill_number}</option>
                                                            ))}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </select>
                                        <ErrorMessage error={errors?.reference_number} />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="date" className="block text-xs text-neutral-700">{lang.date}</label>
                                        <InputDate disabled={data} id="date" onChange={(value) => setDate(moment(value).format('Y-MM-DD'))} selected={Date.parse(date)} value={Date.parse(date)} error={errors?.date} />
                                        <ErrorMessage error={errors?.date} />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3"></div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="currency" className="block text-xs text-neutral-700">{lang.currency}</label>
                                        <select disabled={true} onChange={(e) => setCurrency(e.target.value)} value={currency} name="currency" id="currency" className={`${errors?.currency ? 'border-red-200' : 'border-neutral-200'} ${true ? 'bg-neutral-100 opacity-70' : ''} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}>
                                            {isLoadingCurrency && (
                                                <option value={undefined} selected disabled>{lang.loading_data}</option>
                                            )}
                                            {!isLoadingCurrency && (
                                                <option value={undefined} selected={!reference} disabled>-- {lang.choose} {lang.currency} --</option>
                                            )}
                                            {!isLoadingCurrency && currencySelection?.length !== 0 && currencySelection?.map(row => (
                                                <option value={row.code}>{row.code}</option>
                                            ))}
                                        </select>
                                        <ErrorMessage error={errors?.currency} />
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label for="rate" className="block text-xs text-neutral-700">{lang.exchange_rate}</label>
                                        <input disabled onChange={e => setRate(e.target.value)} value={rate} id="rate" autoComplete="off" type="text" className={`${errors?.rate ? 'border-red-200' : 'border-neutral-200'} bg-neutral-100 opacity-70 block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`} />
                                        <ErrorMessage error={errors?.rate} />
                                    </div>

                                    <div className="col-span-6">
                                        <label for="note" className="block text-xs text-neutral-700">{lang.note}</label>
                                        <textarea onChange={e => setNote(e.target.value)} value={note} name="note" id="note" rows="5" className={`${errors?.note ? 'border-red-200' : 'border-neutral-200'} block w-full px-2 py-2 mt-1 text-sm transition border focus:outline-none rounded-xl focus:border-neutral-400 focus:ring focus:ring-neutral-200`}></textarea>
                                        <ErrorMessage error={errors?.note} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200" />
                </div>
            </div>

            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{lang.tax_and_amount}</h3>
                            <p className="mt-1 text-sm text-gray-600">{lang.more_detailed_form_about_tax_and_more}</p>
                        </div>
                    </div>
                    <div className="mt-5 md:col-span-2 md:mt-0">
                        <div className="overflow-hidden border sm:rounded-xl">
                            <div className="px-4 py-5 space-y-6 bg-white sm:p-6">
                                <fieldset>
                                    <legend className="sr-only">{lang.taxes}</legend>
                                    <div className="text-base font-medium text-gray-900" aria-hidden="true">
                                        {lang.taxes}
                                    </div>
                                    <div className="mt-4 space-y-4">
                                        <div className="grid grid-cols-6 gap-4">
                                            <div className="col-span-6 sm:col-span-3">
                                                <label for="tax_invoice_number" className="block text-xs text-neutral-700">{lang.tax_invoice_number}</label>
                                                <Input onChange={e => setTaxInvoiceNumber(e.target.value)} value={taxInvoiceNumber} error={errors?.tax_invoice_number} id="tax_invoice_number" />
                                                <ErrorMessage error={errors?.tax_invoice_number} />
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset className="space-y-4">
                                    <legend className="text-base font-medium text-gray-900 contents">{lang.amounts}</legend>
                                    <div className="grid grid-cols-6 gap-4">
                                        <div className="col-span-6 sm:col-span-3">
                                            <Label htmlFor="amount" value={lang.amount} />
                                            <InputAmount disabled={data} min={0} onChange={(value) => setAmount(value)} value={parseFloat(amount)} error={errors?.amount} id="amount" />
                                            <ErrorMessage error={errors?.amount} />
                                        </div>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gray-800">{lang.ppn} ({ppnPercentage}%)</p>
                                        <p className="text-xs text-gray-500">{currency && Intl.NumberFormat('id-Id', { style: 'currency', currency }).format(ppn)}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gray-800">{lang.subtotal}</p>
                                        <p className="text-xs text-gray-500">{currency && Intl.NumberFormat('id-Id', { style: 'currency', currency }).format(subtotal)}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gray-800">{lang.max}</p>
                                        <p className="text-xs text-gray-500">{currency && Intl.NumberFormat('id-Id', { style: 'currency', currency }).format(max)}</p>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-8 text-xs text-right">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{data ? lang.update : lang.create}</span>
                </button>
            </div>
        </form>
    )
}

export default Form