import { useLayoutEffect, useState } from "react"
import { useLoaderData, useLocation, useNavigate } from "react-router-dom"
import { motion } from "framer-motion"

import AppLayout from "layouts/app-layout"
import Transition from "components/transition"
import axios from "axios"
import Form from "./form"
import { useAuth } from "contexts/auth-context"

const EditSupplier = () => {
    const { user } = useAuth()
    const supplierData = useLoaderData()

    const location = useLocation()
    const navigate = useNavigate()

    const [prevRouteState, setPrevRouteState] = useState({})

    const [errors, setErrors] = useState({})

    useLayoutEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                from: location.state?.from,
                transition: location.state?.transition
            })
        }

        getPrevRouteState()
    }, [])

    const handleSubmit = (data) => {
        axios.patch(`${process.env.REACT_APP_BACKEND_URL}/supplier/update`, {
            id: supplierData.id,
            name: data.name,
            npwp: data.npwp,
            note: data.note,

            email: data.email,
            fax: data.fax,
            phone: data.phone,
            mobile: data.mobile,
            contact_person: data.contact_person,
            contact_number: data.contact_number,
            address: data.address,
            bill_address: data.bill_address,

            updated_by: user?.name
        }).then(() => {
            navigate({ pathname: '/supplier' })
        }).catch(error => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            console.log(error.response)
        })
    }

    return (
        <AppLayout title={`Edit ${supplierData?.name}`}>
            <div className="p-4 lg:p-8 space-y-16">
                <div>
                    {prevRouteState?.back && (
                        <button className="transition hover:opacity-50" onClick={() => navigate(-1, { state: { transition: "slide" } })}>
                            <motion.h3 layout transition={{ duration: .5, type: "tween" }} layoutId={prevRouteState?.back} className="text-sm">{prevRouteState?.back}</motion.h3>
                        </button>
                    )}
                    <Transition reversed>
                        <h1 className="text-3xl font-medium">
                            {`Edit ${supplierData?.name}`}
                        </h1>
                    </Transition>
                </div>
                <Transition type="slide" reversed>
                    <Form data={supplierData} errors={errors} onSubmit={handleSubmit} />
                </Transition>
            </div>
        </AppLayout >
    )
}

export default EditSupplier