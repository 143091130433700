import { useState } from 'react'
import { Modal } from 'components'
import axios from 'axios'
import ErrorMessage from 'components/forms/error-message'
import Input from 'components/forms/input'
import Label from 'components/forms/label'
import nProgress from 'nprogress'
import toast from 'react-hot-toast'
import { lang } from 'config'
import { PencilIcon } from 'components/icons'

const Content = ({ data, success }) => {
    const [password, setPassword] = useState()

    const [errors, setErrors] = useState({})

    const handleSubmit = (e) => {
        e.preventDefault()
        nProgress.start()

        axios.patch(`${process.env.REACT_APP_GLOBAL_API_URL}/user/update`, {
            initial_phone: data?.phone,
            password
        }).then((response) => {
            success()
            toast.success(response.data.message)
        }).catch(error => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors)
            }
            toast.error(error.response.data.message)
            console.log(error.response)
            nProgress.done()
        })
    }

    return (
        <form onSubmit={handleSubmit} className="mt-8 space-y-8">
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <Label htmlFor="password" value={lang.password} />
                    <Input type="password" onChange={(e) => setPassword(e.target.value)} value={password} id="password" error={errors.password} />
                    <ErrorMessage error={errors.password} />
                </div>
            </div>
            <div className="text-xs">
                <button type="submit" className="items-center px-6 py-3 text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                    <span>{lang.update}</span>
                </button>
            </div>
        </form>
    )
}

const EditPassword = ({ data, onSuccess }) => {
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false)

    const handleSuccess = () => {
        closeModal()
        onSuccess()
    }

    return (
        <>
            <button onClick={openModal} class="inline-flex items-center px-4 py-3 space-x-2 text-xs text-white transition bg-neutral-800 rounded-xl active:hover:scale-90">
                <PencilIcon className="w-4 h-4" stroke={1.5} />
                <span>Ubah Password</span>
            </button>
            <Modal isOpen={isOpen} onClose={closeModal} title="Ubah Password" content={<Content data={data} success={handleSuccess} />} />
        </>
    )
}

export default EditPassword